
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class IncomeService extends BaseApi {


    constructor(){
        super();
    }

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/incomes${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/incomes/${slug}`)
        .catch(this.handleErrorResponse);
    }



    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/incomes`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/incomes/${slug}/update`,data)
        .catch(this.handleErrorResponse);
    }



    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/incomes/${slug}`)
        .catch(this.handleErrorResponse);
    }



    async dashByCustomer(business, date, page = 1, currency )
    {
        return this.http.post(`${this.baseUrl}/business/${business}/dashboard/incomes-per-customer?page=${page}`,
        {
            "start_date": date[0],
            "end_date":date[1],
            "currency":currency
        }).catch(this.handleErrorResponse);
    }


    async pdfDetails(business, id)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/incomes/${id}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

}