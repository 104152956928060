
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ReceivableAccountService extends BaseApi {


    constructor(){
        super();
    }

    async byCustomer(business, customer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers/${customer}/receivable-accounts`)
    }

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/receivable-accounts${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/receivable-accounts/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/receivable-accounts`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/receivable-accounts/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }
    

    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/receivable-accounts/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async updateStatus(business, slug)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/receivable-accounts/${slug}/change-status`)
        .catch(this.handleErrorResponse);
    }
   
   
}