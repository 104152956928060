
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class AgentsPayableAccountService extends BaseApi {


    constructor(){
        super();
    }


    async getPayableAccounts(business, customAgent)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customs-agents/${customAgent}/payable-accounts`)
        .catch(this.handleErrorResponse);
    }

    async readXML(file)
    {
        return this.http.post(`${this.baseUrl}/utils/ca-readXML`,file,{headers: { "Content-Type": "multipart/form-data"}})
        .catch(this.handleErrorResponse);
    }

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/ca-payable-accounts${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/ca-payable-accounts/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/ca-payable-accounts`,data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/ca-payable-accounts/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }
    

    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/ca-payable-accounts/${slug}`)
        .catch(this.handleErrorResponse);
    }

    async export(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/ca-payable-accounts/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    async updateStatus(business, slug)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/ca-payable-accounts/${slug}/change-status`)
        .catch(this.handleErrorResponse);
    }
    
}