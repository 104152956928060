
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class WarrantyService extends BaseApi {


    constructor(){
        super();
    }

    async getSelectByCustomer(business, customer_id)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers/${customer_id}/warranties`)
        .catch(this.handleErrorResponse);
    }

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customs-agents/warranties${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customs-agents/warranties/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/customs-agents/warranties`, data)
        .catch(this.handleErrorResponse);
    }

    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/customs-agents/warranties/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/customs-agents/warranties/${slug}`)
        .catch(this.handleErrorResponse);
    }

}