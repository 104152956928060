
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ContractorPaymentService extends BaseApi {


    constructor(){
        super();
    }


    
    async list(business, construction, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/constructions/${construction}/expenses/payments${filter}`)
        .catch(this.handleErrorResponse);
    }


    async get(business, construction, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/constructions/${construction}/expenses/payments/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, construction, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/constructions/${construction}/expenses/payments`,data)
        .catch(this.handleErrorResponse);
    }

    async update(business, construction, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/constructions/${construction}/expenses/payments/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, construction, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/constructions/${construction}/expenses/payments/${slug}`)
        .catch(this.handleErrorResponse);
    }



   

}