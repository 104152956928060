import AuthService from "./AuthService";
import PayableAccountService from "./PayableAccountService";
import SupplierService from "./SupplierService";
import ReferenceService from "./ReferenceService";
import ConceptService from "./ConceptService";
import BalanceService from "./BalanceService";
import UtilService from "./UtilService";
import ReceivableAccountService from "./ReceivableAccountService";
import CustomerService from "./CustomerService";
import BusinessService from "./BusinessService";
import BankService from "./BankService";
import AccountService from "./AccountService";
import PaymentMethodService from "./PaymentMethodService";
import DepositWithdrawService from "./DepositWithdrawService";
import TransferService from "./TransferService";
import IncomeService from "./IncomeService";
import OutcomeService from "./OutcomeService";
import DepositService from "./DepositService";
import WithdrawService from "./WithdrawService";
import InvoiceService from "./InvoiceService";
import UserService from "./UserService";
import RolService from "./RolService";
import ReportService from './ReportService';
import AgentsPayableAccountService from './AgentsPayableAccountService';
import CustomAgentService from './CustomAgentService';
import PeceAccountService from './PeceAccountService';
import AdvanceService from './AdvanceService';
import PaymentService from './PaymentService';
import DelayService from './DelayService';
import WarrantyService from './WarrantyService';
import CreditNoteService from './CreditNoteSevice';
import ConstructionTypeService from "./ConstructionTypeService";
import ContractorCXPService from "./ContractorCXPService";
import ContractorPaymentService from "./ContractorPaymentService";
import ContractorWarrantyService from "./ContractorWarrantyService";
import ContractorAdditionService from "./contractorAdditionService";
import ContractorReductionService from "./ContractorReductionService";
import ExtraordinaryService from "./ExtraordinaryService";
import DashboardService from "./DashboardService";
import ConstructionCXCService from "./ConstructionCXCService";
import ConstructionEstimationService from "./ConstructionEstimationServie";
import ConstructionWarrantyService from "./ConstructionWarrantyService";
import ConstructionAditivaService from "./ConstructionAditivaService";
import ConstructionDeductivaService from "./ConstructionDeductivaService";
import IncomeCreditNoteService from "./IncomeCreditNoteService";
import PositiveBalanceService from "./PositiveBalanceService";
import PayrollService from "./PayrollService";
import LoanService from "./LoanService";
import ConstructionService from "./ConstructionService";
import LoanOutcomeService from "./LoanOutcomeService";
import LoanIncomeService from "./LoanIncomeService";

export const $api = {
   
    auth: new AuthService(),
    payableAccount: new PayableAccountService(), 
    receivableAccount: new ReceivableAccountService(),
    supplier: new SupplierService(),
    reference: new ReferenceService(),
    concept: new ConceptService(),
    balance: new BalanceService(),
    utils: new UtilService(),
    customer: new CustomerService(),
    business: new BusinessService(),
    bank: new BankService(),
    account: new AccountService(),
    paymentMethod: new PaymentMethodService(),
    depositWithdraw: new DepositWithdrawService(),
    transfer: new TransferService(),
    income: new IncomeService(),
    outcome: new OutcomeService(),
    deposit: new DepositService(),
    withdraw: new WithdrawService(),
    invoice: new InvoiceService(),
    user: new UserService(),
    rol: new RolService(),
    report: new ReportService(),
    agentPayableAccount: new AgentsPayableAccountService(),
    customAgent: new CustomAgentService(),
    peceAccount: new PeceAccountService(),
    advance: new AdvanceService(),
    payment: new PaymentService(),
    delay: new DelayService(),
    warranty: new WarrantyService(),
    creditNote: new CreditNoteService(),
    constructionType: new ConstructionTypeService(),
    contractorCXP: new ContractorCXPService(),
    contractorPayment: new ContractorPaymentService(),
    contractorWarranty: new ContractorWarrantyService(),
    contractorAddition: new ContractorAdditionService(),
    contractorReduction: new ContractorReductionService(),
    constructionCXC: new ConstructionCXCService(),
    constructionPayment: new ConstructionEstimationService(),
    constructionWarranty: new ConstructionWarrantyService(),
    constructionAddition: new ConstructionAditivaService(),
    constructionReduction: new ConstructionDeductivaService(),
    extraordinary: new ExtraordinaryService(),
    dashboard: new DashboardService(),
    ncIncome: new IncomeCreditNoteService(),
    positiveBalance: new PositiveBalanceService(),
    payroll: new PayrollService(),
    loan : new LoanService(),
    construction: new ConstructionService(),
    loanOutCome: new LoanOutcomeService(),
    loanIncome: new LoanIncomeService(),
    
};