
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class PayrollService extends BaseApi {


    constructor(){
        super();
    }

    async index(business)
    {
        return this.http.get(`${this.baseUrl}/rrhh/paysheet/business/${business}`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/rrhh/paysheet/${id}`)
        .catch(this.handleErrorResponse);
    }



    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/rrhh/paysheet/${id}`, data)
        .catch(this.handleErrorResponse);
    }


    async indexPayrollPayments(business, type, start, end)
    {
        return this.http.get(`${this.baseUrl}/rrhh/payroll-payments/business/${business}/${type}/${start}/${end}`)
        .catch(this.handleErrorResponse);
    }

    async setPayment(business, type, start, end, data)
    {
        return this.http.post(`${this.baseUrl}/rrhh/payroll-payments/business/${business}/${type}/${start}/${end}`, data)
        .catch(this.handleErrorResponse);
    }

    async cancelPayment(id)
    {
        return this.http.delete(`${this.baseUrl}/rrhh/payroll-payments/${id}`)
        .catch(this.handleErrorResponse);
    }


    async cancelMultiplePayments(data)
    {
        return this.http.delete(`${this.baseUrl}/rrhh/payroll-payments/many`, { data: data })
        .catch(this.handleErrorResponse);
    }


    async readPayFile(business, type, start, end, file)
    {
        return this.http.post(`${this.baseUrl}/rrhh/payroll-payments/business/${business}/${type}/${start}/${end}/read-file`, file)
        .catch(this.handleErrorResponse);
    }

    downloadFormatExample()
    {
        return this.http.get(`${this.baseUrl}/rrhh/payroll-payments/example-file`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



    async annualBudget(year)
    {
        return this.http.get(`${this.baseUrl}/rrhh/reports/payrolls/annual-budget/${year}`)
        .catch(this.handleErrorResponse);
    }

    exportAnnualBudget(year)
    {
        return this.http.get(`${this.baseUrl}/rrhh/reports/payrolls/annual-budget/${year}/excel`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }





}