
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class CustomerService extends BaseApi {


    constructor(){
        super();
    }

    async list(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/customers`, data)
        .catch(this.handleErrorResponse);
    }

    async update(business, slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/customers/${slug}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/customers/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async getReport(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    /**
     * GET
     * Get customers for selectors
     * @param {string} business business slug
     */
    async getSelectCustomers(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/catalogs/customers`)
        .catch(this.handleErrorResponse);
    }



    async getSelectPastDueBalance(business, search = '', page = 1)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/list/customers/pastDueBalance?search=${search}&page=${page}`)
        .catch(this.handleErrorResponse);
    }

    async constructionReceivableAccount(business, construction, customer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers/${customer}/constructions/${construction}/receivable-accounts`)
        .catch(this.handleErrorResponse);
    }

}