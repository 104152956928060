<template>
    <div>
        <div :class="`select__container rounded ${classesContainer}`">
           
            
            <div class="select__input">
                
                <v-autocomplete 
                    :label="label"
                    class="alternative-selector elevation-0"
                    flat
                    color="primary" 
                    v-model="internalValue" 
                    hide-details="auto" 
                    :items="items" 
                    outlined
                    dense
                    item-color="primary"
                    background-color='transparent'
                    :rules="rule"
                    :disabled="disabled"
                    :search-input.sync="search"
                    id="alt-selector"
                    :clearable="clearableIn"
                    :return-object="getObject"
                    @change="changed"
                >
                <template v-slot:append-item v-if="!internalLastPage">
                    <div v-intersect="onIntersect"></div>
                </template>
                </v-autocomplete>
            </div>
        </div>
       
        <v-progress-linear color="primary" indeterminate  height="2" v-if="loading"></v-progress-linear>
    </div>
    
</template>
<script>
    import debounce from '../helpers/debounce';
    export default {
        props: ['clearableIn', 'classesContainer', 'lastPage', 'valueInput', 'rule', 'label', 'items', 'loading', 'disabled', 'page', 'getObject'],
        computed: {
            internalValue: {
                get: function() {
                    return this.valueInput
                },
                set: function(value) {
                    this.$emit('valueChange', value)
                }
            },
            internalItems: {
                get: function() {
                    return this.items
                },
                set: function(value) {
                    this.$emit('itemsChange', value)
                }
            },
            internalLastPage: {
                get: function() {
                    return this.lastPage
                },
                set: function(value) {
                    this.$emit('lastPageChange', value)
                }
            },
            internalPage: {
                get: function() {
                    return this.page
                },
                set: function(value) {
                    this.$emit('pageChange', value)
                }
            }
        
        
        },
        data(){
            return{
                search:"",
                isInputFunctionRunning: false,
                lock: false,
            }
        },
        methods:{
          
            onIntersect(entries, observer, isIntersecting)
            {
                
                if (isIntersecting && !this.internalLastPage && !this.search) {
                    if(this.internalItems.length > 8){
                        let p = this.internalPage + 1;
                        this.internalPage = p;
                        // this.listProvidersByPastDueBalance({options:{search:this.search,page:this.internalPage}});
                        this.$emit('onIntersect', {search:this.search??'',page:p});
                    }else{
                        let p =  1;
                        this.internalPage = p;
                        // this.listProvidersByPastDueBalance({options:{search:this.search,page:this.internalPage}});
                        this.$emit('onIntersect', {search:this.search??'',page:p});
                    }
                }
                
            },
            changed(v)
            {
                //console.log('change !', v)
                if(v != null)
                    this.lock = true;
                this.$emit('onChange', v);
            }
        },
        watch: {
            search: debounce(function(newVal) {
                if(this.lock){
                    this.lock = false;
                    //console.log(oldVal);
                    return;
                }
                if(this.isInputFunctionRunning)
                    return;
                ////console.log(newVal)
                this.internalPage = 1;
               

                // const exist = this.internalItems.find(elem => elem.text.match(new RegExp(newVal, 'gi'))|| elem.text.match(new RegExp(oldVal, 'gi')))
                // console.log(this.isInputFunctionRunning, this.internalLastPage, exist, newVal, oldVal, this.internalValue)
                // if ((this.isInputFunctionRunning ||this.internalLastPage ) && newVal !=null) {
                //     if(!this.isInputFunctionRunning && newVal == ''){
                //         this.internalLastPage = false;
                //         this.internalPage = 1;
                //         this.internalItems = [];
                //         this.$emit('searchSync', {search:'', page:1});
                //     }
                //     return;
                // }
               
                this.isInputFunctionRunning = true;
                //console.log('fun running')
             
                this.internalLastPage = false;
                this.internalPage = 1;
                this.internalItems = [];
                // this.listProvidersByPastDueBalance({options:{search:newVal,page:this.page}});
                this.$emit('searchSync', {search:newVal??'', page:this.internalPage});
                this.isInputFunctionRunning = false;
                //console.log('fun not run')
            },1000),
            
        },
    }
    
</script>

<style lang="scss">


    .select{
        &__container{
            padding-left: 1px;
            border: $lineColor-1 1px solid;
            display: flex;
            align-items: baseline;
        }

        &__label{
            cursor: default;
            min-width: fit-content;
            &--text{
                //font-weight: bold !important;

            }
        }

        &__input{
            // margin-left: 10px;
            // width: -webkit-fill-available;
        }
    }
    .alternative-selector{
        // min-width: 1rem !important;
        // padding: 0.3125rem !important;
        // padding-bottom: px !important;
        margin-top: 0px !important;
        
    }

    // .v-text-field .v-input__control .v-input__slot input, select{
    //   //margin-bottom: 8px;
    //     margin-top: 0px !important;
    //     padding: 0;
    // }
    #alt-selector{
        
        margin-top: 12px !important;
        // font-size: px !important;
        font-weight: 400 !important;
        padding-right: 0 !important;
        
    }
    .alternative-selector .v-input__control .v-input__slot input{
        margin-top: 0px !important;
    }
</style>