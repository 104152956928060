import BaseApi from "./BaseApi";

export default class PositiveBalanceService extends BaseApi {


    constructor(){
        super();
    }


    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customs-agents/positive-balances${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customs-agents/positive-balances/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/customs-agents/positive-balances`, data)
        .catch(this.handleErrorResponse);
    }

    async update(business, slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/customs-agents/positive-balances/${slug}`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/customs-agents/positive-balances/${slug}`)
        .catch(this.handleErrorResponse);
    }

}