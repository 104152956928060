
// import router from "../router/index";
// import store from "../store";
import axios from 'axios';

export default class BanxioSIEAPIService {

    // baseUrl = process.env.VUE_APP_BACKEND_URL;
    http = axios;

    apiUrl = process.env.VUE_APP_BANXICO_SIE_API_URL;
    token = process.env.VUE_APP_BANXICO_SIE_API_TOKEN;
    //idSerie
    dolarIDSeries = 'SF43718'; //Tipo de cambio Pesos por dólar E.U.A. Tipo de cambio para solventar obligaciones denominadas en moneda extranjera Fecha de determinación (FIX)
    euroIDSeries = 'SF46410'; //Cotización de las divisas que conforman la canasta del DEG Respecto al peso mexicano Euro
    CDolarIDSeries = 'SF57771'; //Tipos de Cambio para Revalorización de Balance del Banco de México CAD Canadá (Dólar) Tipo en pesos

    constructor(){
        //super();
        
    }

    async getCurrencyDataMXN()
    {
        
        return this.http.get(`${this.apiUrl}/${this.getAllIdSeries()}/datos/oportuno?token=${this.token}`,{
            transformRequest: [
              (data, headers) => {
                delete headers.common['Authorization'];
                return JSON.stringify(data);
              },
            ],
        });
    }


    processInformation(data)
    {
        console.log("Enter");
        let cad = 0;
        let usd = 0;
        let eur = 0;
        data.bmx.series.forEach(element => {
            if(element.idSerie === this.getCDolarIDSeries())
                cad = parseFloat(element?.datos[0]?.dato??0);

            if(element.idSerie === this.getDolarIDSeries())
                usd = parseFloat(element?.datos[0]?.dato??0);

            if(element.idSerie === this.getEuroIDSeries())
                eur = parseFloat(element?.datos[0]?.dato??0);
        });
        return {cad,usd,eur};
    }

    convertCurrencyTo(currencies, from, to, amount)
    {
        // console.log(currencies, from, to, amount)
        if(from == undefined)
            return amount;
        
        if(currencies[from] === 0 || currencies[to] === 0)
            return amount;

        if(from === to){
            return parseFloat(amount).toFixed(2);
        }
        if(from === 'mxn'){
            return parseFloat(amount/currencies[to]).toFixed(2);
        }
        if(to === 'mxn'){
            return parseFloat(amount*currencies[from]).toFixed(2);
        }
        return parseFloat((currencies[from]/currencies[to])*amount).toFixed(2);
    }

    getAllIdSeries ()
    {
        return `${this.getEuroIDSeries()},${this.getDolarIDSeries()},${this.getCDolarIDSeries()}`;
    }


    //get Dolar IdSeries
    getDolarIDSeries()
    {
        return this.dolarIDSeries;
    }

    getEuroIDSeries()
    {
        return this.euroIDSeries;
    }

    getCDolarIDSeries()
    {
        return this.CDolarIDSeries;
    }

   





}