
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ConstructionTypeService extends BaseApi {


    constructor(){
        super();
    }


    async getSelect(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/catalogs/construction-types`)
        .catch(this.handleErrorResponse);
    }

    
    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/construction-types${filter}`)
        .catch(this.handleErrorResponse);
    }


    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/construction-types/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/construction-types`,data)
        .catch(this.handleErrorResponse);
    }

    async update(business, slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/construction-types/${slug}`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/construction-types/${slug}`)
        .catch(this.handleErrorResponse);
    }



   

}