
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class InvoiceService extends BaseApi {


    constructor(){
        super();
    }


    async list(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/invoices`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/invoices/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/invoices`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business,slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/invoices/${slug}`, data)
        .catch(this.handleErrorResponse);
    }



    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/invoices/${slug}`)
        .catch(this.handleErrorResponse);
    }
    

    async getReceiptFile(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/invoices/${slug}/receipt`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    async byCustomer(business, customer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers/${customer}/invoices`)
        .catch(this.handleErrorResponse);
    }


}