
import BaseApi from "./BaseApi";

export default class LoanOutcomeService extends BaseApi {

    constructor(){
        super();
    }

    
    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/outcomes/loans${filter}`)
        .catch(this.handleErrorResponse);
    }


    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/outcomes/loans/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/outcomes/loans`,data)
        .catch(this.handleErrorResponse);
    }

    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/outcomes/loans/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/outcomes/loans/${slug}`)
        .catch(this.handleErrorResponse);
    }



    //
    // * PAYMENTS
    //

    async storePayment(business, loanSlug,  data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/outcomes/loans/${loanSlug}/payment`,data)
        .catch(this.handleErrorResponse);
    }


   

}