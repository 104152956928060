import Home from '../views/Home.vue';
//import testMiddle from "../middleware/testMiddle";
import AuthMiddleware from '../middleware/AuthMiddleware';
import VueGateMiddleware from '../middleware/VueGateMiddleware';

const initialRoutes = [
    {
        path: '/',
        name: 'Home',
        component: () => Home,
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
            
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Index.vue'),
        meta: {
            parent:"",
            auth: true,
            title: 'Dashboard',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'dashboard.show|business_permission_dashboard.show'
            
        }
    },
    
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../layouts/Login.vue'),
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
  },
];

// const cgaaRoutes = [
//     {
//         path: '/cgaa',
//         name: 'CGAA',
//         component: () => import(/* webpackChunkName: "about" */ '../views/cgaa/Index.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Cuenta de gastos agentes aduanales',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'ca_payable_accounts.show'
//         }
//     },
//     {
//         path: '/cgaa/new',
//         name: 'CGAACreate',
//         component: () => import(/* webpackChunkName: "about" */ '../views/cgaa/Create.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Nueva cuenta de gastos agentes aduanales',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'ca_payable_accounts.create'
//         }
//     },
//     {
//         path: '/cgaa/:slug/edit',
//         name: 'CGAAEdit',
//         component: () => import(/* webpackChunkName: "about" */ '../views/cgaa/Edit.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Editar cuenta de gastos agentes aduanales',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'ca_payable_accounts.update'
//         }
//     },
//     {
//         path: '/cgaa/:slug/details',
//         name: 'CGAADetails',
//         component: () => import(/* webpackChunkName: "about" */ '../views/cgaa/Details.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Detalles cuenta de gastos agentes aduanales',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'ca_payable_accounts.show'
//         }
//     },
// ];

const summaryRoutes = [
    {
        path: '/summary',
        name: 'Summary',
        component: () => import(/* webpackChunkName: "about" */ '../views/summary/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Estado de resultados',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'income_statement.show|business_permission_income_statement.show'
        }
    },
    
];


//new incomes
const cxcRoutes = [
    /*
        CXC
    */
    {
        path: '/cxc',
        name: 'CXC',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxc/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|receivable_accounts.show'
        }
    },
    {
        path: '/cxc/new',
        name: 'CXCCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxc/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nueva Venta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|receivable_accounts.create'
        }
    },
    {
        path: '/cxc/:slug/edit',
        name: 'CXCEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxc/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Venta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|receivable_accounts.update'
        }
    },
    {
        path: '/cxc/:slug/details',
        name: 'CXCDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxc/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles de venta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|receivable_accounts.show'
        }
    },

    /*
        INCOME
    */
    {
        path: '/incomes',
        name: 'Incomes',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomes/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Cobros',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|incomes.show'
        }
    },
    {
        path: '/incomes/new',
        name: 'IncomesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomes/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Nuevo cobro',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|incomes.create'
        }
    },
    {
        path: '/incomes/:slug/edit',
        name: 'IncomesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomes/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Editar cobro',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|incomes.update'
        }
    },
    {
        path: '/incomes/:slug/details',
        name: 'IncomesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomes/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Detalles de cobro',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|incomes.show'
        }
    },

    /*
        DEPOSITS (OTHER INCOME)
    */
    {
        path: '/deposits',
        name: 'Deposits',
        component: () => import(/* webpackChunkName: "about" */ '../views/deposits/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Otros Ingresos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|depositos.show'
        }
    },
    {
        path: '/deposits/new',
        name: 'DepositsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/deposits/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Nuevo Ingreso',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|depositos.create'
        }
    },
    {
        path: '/deposits/:slug/edit',
        name: 'DepositsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/deposits/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Editar Ingreso',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|depositos.update'
        }
    },
    {
        path: '/deposits/:slug/details',
        name: 'DepositsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/deposits/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Detalles Ingreso',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|depositos.show'
        }
    },

    /*
        DEPOSITS (OTHER INCOME)
    */
    {
        path: '/nc-income',
        name: 'NCIncomes',
        component: () => import(/* webpackChunkName: "about" */ '../views/ncIncomes/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Notas de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_credit_notes.show'
        }
    },
    {
        path: '/nc-income/new',
        name: 'NCIncomesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/ncIncomes/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Nueva Nota de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_credit_notes.create'
        }
    },
    {
        path: '/nc-income/:slug/edit',
        name: 'NCIncomesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/ncIncomes/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Editar Nota de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_credit_notes.update'
        }
    },
    {
        path: '/nc-income/:slug/details',
        name: 'NCIncomesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/ncIncomes/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Detalles Nota de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_credit_notes.show'
        }
    },
    /**
     * ! LOANS INCOME
    */
    {
        path: '/loans-income',
        name: 'LoansIncome',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansIncome/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'INGRESOS - Préstamos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_loans.show'
        }
    },
    {
        path: '/loans-income/new',
        name: 'LoansIncomeCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansIncome/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'INGRESOS - Nuevo Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_loans.create'
        }
    },
    {
        path: '/loans-income/:slug/edit',
        name: 'LoansIncomeEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansIncome/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'INGRESOS - Editar Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_loans.update'
        }
    },
    {
        path: '/loans-income/:slug/details',
        name: 'LoansIncomeDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansIncome/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'INGRESOS - Detalles Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_loans.update'
        }
    },
    {
        path: '/loans-income/:slug/payments/new',
        name: 'LoansIncomePaymentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansIncome/payments/CreatePayment.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'INGRESOS - pago a Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxc.show|income_loans.update'
        }
    },
];

//new outcomes
const cxpRoutes = [
    /*
        CXP
    */
    {
        path: '/cxp',
        name: 'CXP',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.show|business_permission_cxp.show'
        }
    },
    {
        path: '/cxp/new',
        name: 'CXPCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva Compra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.create|business_permission_cxp.show'
        }
    },
    {
        path: '/cxp/:slug/edit',
        name: 'CXPEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Compra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.update|business_permission_cxp.show'
        }
    },
    {
        path: '/cxp/:slug/details',
        name: 'CXPDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Detalles de compra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.show|business_permission_cxp.show'
        }
    },
    {
        path: '/cxp/:slug/advanced',
        name: 'AdvancedCXPIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/advanceCXP/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Anticipos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.show|business_permission_cxp.show'
        }
    },
    {
        path: '/cxp/:slug/advanced/new',
        name: 'AdvancedCXPCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/advanceCXP/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo Anticipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.create|business_permission_cxp.show'
        }
    },
    {
        path: '/cxp/:slug/advanced/:acxp/details',
        name: 'AdvancedCXPDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/advanceCXP/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Detalles de Anticipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.show|business_permission_cxp.show'
        }
    },
    {
        path: '/cxp/:slug/advanced/:acxp/edit',
        name: 'AdvancedCXPEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/cxp/advanceCXP/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Anticipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'payable_accounts.update|business_permission_cxp.show'
        }
    },


    /*
        OUTCOME
    */
    {
        path: '/outcome',
        name: 'Outcome',
        component: () => import(/* webpackChunkName: "about" */ '../views/outcome/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Pagos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'outcomes.show|business_permission_cxp.show'
        }
    },
    {
        path: '/outcome/new',
        name: 'OutcomeCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/outcome/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Nuevo pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'outcomes.create|business_permission_cxp.show'
        }
    },
    {
        path: '/outcome/:slug/edit',
        name: 'OutcomeEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/outcome/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Editar pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'outcomes.update|business_permission_cxp.show'
        }
    },
    {
        path: '/outcome/:slug/details',
        name: 'OutcomeDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/outcome/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Detalles de pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'outcomes.show|business_permission_cxp.show'
        }
    },

    /*
        WITHDRAWS
    */
    {
        path: '/withdraws',
        name: 'Withdraws',
        component: () => import(/* webpackChunkName: "about" */ '../views/withdraws/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Otros Gastos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'retiros.show|business_permission_cxp.show'
        }
    },
    {
        path: '/withdraws/new',
        name: 'WithdrawsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/withdraws/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Nuevo Gasto',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'retiros.create|business_permission_cxp.show'
        }
    },
    {
        path: '/withdraws/:slug/edit',
        name: 'WithdrawsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/withdraws/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Editar Gasto',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'retiros.update|business_permission_cxp.show'
        }
    },
    {
        path: '/withdraws/:slug/details',
        name: 'WithdrawsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/withdraws/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Detalles Gasto',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'retiros.show|business_permission_cxp.show'
        }
    },


    /*
        Credit Notes (NC OUTCOME)
    */
    {
        path: '/nc-outcome',
        name: 'CreditNotes',
        component: () => import(/* webpackChunkName: "about" */ '../views/creditNotes/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Notas de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'credit_notes.show|business_permission_cxp.show'
        }
    },
    {
        path: '/nc-outcome/new',
        name: 'CreditNotesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/creditNotes/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Nueva Nota de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'credit_notes.create|business_permission_cxp.show'
        }
    },
    {
        path: '/nc-outcome/:slug/details',
        name: 'CreditNotesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/creditNotes/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Detalles Nota de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'credit_notes.show|business_permission_cxp.show'
        }
    },
    {
        path: '/nc-outcome/:slug/edit',
        name: 'CreditNotesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/creditNotes/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Editar Nota de crédito',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'credit_notes.update|business_permission_cxp.show'
        }
    },

    /**
     * ! LOANS OUTCOME
    */
    {
        path: '/loans-outcome',
        name: 'LoansOutcome',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansOutcome/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Préstamos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxp.show|outcomes_loans.show'
        }
    },
    {
        path: '/loans-outcome/new',
        name: 'LoansOutcomeCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansOutcome/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Nuevo Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxp.show|outcomes_loans.create'
        }
    },
    {
        path: '/loans-outcome/:slug/edit',
        name: 'LoansOutcomeEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansOutcome/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Editar Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxp.show|outcomes_loans.update'
        }
    },
    {
        path: '/loans-outcome/:slug/details',
        name: 'LoansOutcomeDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansOutcome/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Detalles Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxp.show|outcomes_loans.show'
        }
    },
    {
        path: '/loans-outcome/:slug/payments/new',
        name: 'LoansOutcomePaymentCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/loansOutcome/payments/CreatePayment.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'EGRESOS - Pago a Préstamo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_cxp.show|outcomes_loans.show'
        }
    },
]


// const loanRoutes = [
//     /**
//      * ! LOANS
//     */
//     {
//         path: '/loans',
//         name: 'Loans',
//         component: () => import(/* webpackChunkName: "about" */ '../views/loans/Index.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Préstamos intercompañia',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'business_permission_loans.show|loans.show'
//         }
//     },
//     {
//         path: '/loans/new',
//         name: 'LoansCreate',
//         component: () => import(/* webpackChunkName: "about" */ '../views/loans/Create.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Préstamos intercompañia - Nuevo préstamo',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'business_permission_loans.show|loans.create'
//         }
//     },
//     {
//         path: '/loans/:slug/edit',
//         name: 'LoansEdit',
//         component: () => import(/* webpackChunkName: "about" */ '../views/loans/Edit.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Préstamos intercompañia - editar préstamo',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'business_permission_loans.show|loans.update'
//         }
//     },
//     {
//         path: '/loans/:slug/details',
//         name: 'LoansDetails',
//         component: () => import(/* webpackChunkName: "about" */ '../views/loans/Details.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Préstamos intercompañia - Detalles préstamo',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'business_permission_loans.show|loans.show'
//         }
//     },
//     {
//         path: '/loans/:slug/payment',
//         name: 'LoansCreatePayment',
//         component: () => import(/* webpackChunkName: "about" */ '../views/loans/payments/CreatePayment.vue'),
//         meta: {
//             parent:'',
//             auth: true,
//             title: 'Préstamos intercompañia - Pago a préstamo',
//             middlewares: {AuthMiddleware, VueGateMiddleware},
//             permissions: 'business_permission_loans.show|loans.update'
//         }
//     },
// ];

const invoiceRoutes = [
    {
        path: '/invoices',
        name: 'Invoices',
        component: () => import(/* webpackChunkName: "about" */ '../views/invoices/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Invoices',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_invoices.show|invoices.show'
        }
    },
    {
        path: '/invoices/new',
        name: 'InvoicesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/invoices/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo Invoice',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_invoices.show|invoices.create'
        }
    },
    {
        path: '/invoices/:slug/edit',
        name: 'InvoicesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/invoices/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Invoice',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_invoices.show|invoices.update'
        }
    },
];

const customAgentRoutes = [
    /**
     * ! PECE ACCOUNTS
     */
    {
        path: '/pece-accounts',
        name: 'PeceAccounts',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/peceAccounts/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Cuentas PECE',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|pece_accounts.show'
        }
    },
    {
        path: '/pece-accounts/new',
        name: 'PeceAccountsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/peceAccounts/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Nueva Cuenta PECE',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|pece_accounts.create'
        }
    },
    {
        path: '/pece-accounts/:slug/edit',
        name: 'PeceAccountsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/peceAccounts/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Editar Cuenta PECE',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|pece_accounts.update'
        }
    },
    {
        path: '/pece-accounts/:slug/details',
        name: 'PeceAccountsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/peceAccounts/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Detalles Cuenta PECE',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|pece_accounts.show'
        }
    },
    /**
     * ! POSITIVE BALANCE
     */
    {
        path: '/positive-balance',
        name: 'PositiveBalance',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/positiveBalance/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Saldos a favor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|ca_positive_balances.show'
        }
    },
    {
        path: '/positive-balance/new',
        name: 'PositiveBalanceCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/positiveBalance/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Nuevo Saldo a favor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|ca_positive_balances.create'
        }
    },
    {
        path: '/positive-balance/:slug/edit',
        name: 'PositiveBalanceEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/positiveBalance/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Editar Saldo a favor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|ca_positive_balances.update'
        }
    },

    /**
     * ! ADVANCES
     */
    {
        path: '/advances',
        name: 'Advances',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/advances/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Anticipos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|advances.show'
        }
    },
    {
        path: '/advances/new',
        name: 'AdvancesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/advances/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Nuevo Anticipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|advances.create'
        }
    },
    {
        path: '/advances/:slug/edit',
        name: 'AdvancesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/advances/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Editar Anticipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|advances.update'
        }
    },
    {
        path: '/advances/:slug/details',
        name: 'AdvancesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/advances/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Detalles Anticipo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|advances.show'
        }
    },

    /**
     * ! DELAYS
     */
    {
        path: '/delays',
        name: 'Delays',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/delays/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Demoras',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|demoras.show'
        }
    },
    {
        path: '/delays/new',
        name: 'DelaysCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/delays/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Demoras',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|demoras.create'
        }
    },
    {
        path: '/delays/:slug/edit',
        name: 'DelaysEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/delays/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Demoras',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|demoras.update'
        }
    },
    {
        path: '/delays/:slug/details',
        name: 'DelaysDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/delays/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Detalle Demoras',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|demoras.show'
        }
    },


    /**
     * ! WARRANTIES
     */
    {
        path: '/warranties',
        name: 'Warranties',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/warranties/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Garantías',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|warranties.show'
        }
    },
    {
        path: '/warranties/new',
        name: 'WarrantiesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/warranties/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Garantías',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|warranties.create'
        }
    },
    {
        path: '/warranties/:slug/edit',
        name: 'WarrantiesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/warranties/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Garantías',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|warranties.update'
        }
    },
    {
        path: '/warranties/:slug/details',
        name: 'WarrantiesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/warranties/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Detalle Garantía',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|warranties.show'
        }
    },



    /**
     * ! Payable accounts
     */
    {
        path: '/payable-accounts',
        name: 'PayableAccounts',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payableAccounts/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Cuentas de gastos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|ca_payable_accounts.show'
        }
    },
    {
        path: '/payable-accounts/new',
        name: 'PayableAccountsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payableAccounts/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Nueva cuenta de gastos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|ca_payable_accounts.create'
        }
    },
    {
        path: '/payable-accounts/:slug/edit',
        name: 'PayableAccountsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payableAccounts/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Editar cuenta de gastos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|ca_payable_accounts.update'
        }
    },
    {
        path: '/payable-accounts/:slug/details',
        name: 'PayableAccountsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payableAccounts/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Detalles cuenta de gastos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|ca_payable_accounts.show'
        }
    },

    /**
     * ! Payments
     */
    {
        path: '/payments',
        name: 'Payments',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payments/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Pagos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|payments.show'
        }
    },
    {
        path: '/payments/new',
        name: 'PaymentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payments/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Nuevo Pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|payments.create'
        }
    },
    {
        path: '/payments/:slug/edit',
        name: 'PaymentsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payments/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Editar Pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|payments.update'
        }
    },
    {
        path: '/payments/:slug/details',
        name: 'PaymentsDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/payments/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Detalles Pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|payments.show'
        }
    },

    /**
     * ! Extraordinary
     */
    {
        path: '/extraordinaries',
        name: 'Extraordinaries',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/extraordinaries/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Extraordinarios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|extraordinaries.show'
        }
    },
    {
        path: '/extraordinaries/new',
        name: 'ExtraordinariesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/extraordinaries/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Extraordinarios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|extraordinaries.create'
        }
    },
    {
        path: '/extraordinaries/:slug/edit',
        name: 'ExtraordinariesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/extraordinaries/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Extraordinarios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|extraordinaries.update'
        }
    },
    {
        path: '/extraordinaries/:slug/details',
        name: 'ExtraordinariesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/extraordinaries/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Agentes Aduanales - Extraordinarios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|extraordinaries.show'
        }
    },

    /**
     * ! CUSTOM AGENTS
    */
    {
        path: '/customs-agents',
        name: 'CustomsAgents',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/customAgents/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Agentes aduanales',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|customs_agents.show'
        }
    },
    {
        path: '/customs-agents/new',
        name: 'CustomsAgentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/customAgents/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo - Agentes aduanales',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|customs_agents.create'
        }
    },
    {
        path: '/customs-agents/:slug/edit',
        name: 'CustomsAgentsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/customAgents/customAgents/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo - Agentes aduanales',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_customs_agents.show|customs_agents.update'
        }
    },
];
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
//construnctions catalog
const construnctionRoutes = [
    /***
     * 
     * ! Constructions
     * 
    */
    {
        path: '/catalogs/constructions',
        name: 'Constructions',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/constructions/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Obras',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions_dynamic.show|constructions.show'
        }
    },
    {
        path: '/catalogs/constructions/new',
        name: 'ConstructionsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/constructions/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva Obra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions_dynamic.show|constructions.create'
        }
    },
    {
        path: '/catalogs/constructions/:slug/edit',
        name: 'ConstructionsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/constructions/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Obra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions_dynamic.show|constructions.update'
        }
    },

    /***
     * 
     * ! CONSTRUCTION TYPES
     * 
    */
    {
        path: '/catalogs/construction-types',
        name: 'ConstructionTypes',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/constructionTypes/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Tipos de obra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|construction-types.show'
        }
    },
    {
        path: '/catalogs/construction-types/new',
        name: 'ConstructionTypesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/constructionTypes/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo Tipo de obra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|construction-types.create'
        }
    },
    {
        path: '/catalogs/construction-types/:slug/edit',
        name: 'ConstructionTypesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/constructionTypes/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar tipo de obra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|construction-types.update'
        }
    },

    /**
     * ! income x obra
    */
    {
        path: '/reports/balance-due/contractors/income',
        name: 'ReportContractorsIncome',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/contractors/income/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingreso x Obra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|reports_contractors_cxc.show'
        }
    },

    /**
    * ! Saldo x obra
    */
    {
        path: '/reports/balance-due/contractors/balance',
        name: 'ReportContractorsBalance',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/contractors/balanceDue/balance.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Saldo x Obra',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|contractors_balance.show'
        }
    },

    
];
//* Dynamic */
const contractorsRoutes = [
    /**
     * ! Contractors CXP
     */
    {
        path: '/contractors-cxp/:construction',
        name: 'ContractorsCXP',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractors/cxp/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Contratos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_payable_accounts.show'
        }
    },
    {
        path: '/contractors-cxp/:construction/new',
        name: 'ContractorsCXPCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractors/cxp/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Nueva Contratos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_payable_accounts.create'
        }
    },
    {
        path: '/contractors-cxp/:construction/:slug/edit',
        name: 'ContractorsCXPEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractors/cxp/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Editar Contrato',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_payable_accounts.update'
        }
    },
    {
        path: '/contractors-cxp/:construction/:slug/details',
        name: 'ContractorsCXPDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractors/cxp/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Detalles Contrato',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_payable_accounts.show'
        }
    },

    /**
     * ! Contractors PAYMENTS
     */
    {
        path: '/contractors-estimations/:construction',
        name: 'ContractorsPayments',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractors/payments/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Estimaciones',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_payments.show'
        }
    },
    {
        path: '/contractors-estimations/:construction/new',
        name: 'ContractorsPaymentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractors/payments/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Nueva Estimacion',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_payments.create'
        }
    },
    {
        path: '/contractors-estimations/:construction/:slug/edit',
        name: 'ContractorsPaymentsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractors/payments/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Editar Estimacion',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_payments.update'
        }
    },

    /**
     * ! Contractors WARRANTIES
     */
    {
        path: '/contractors-warranties/:construction',
        name: 'ContractorsWarranties',
        component: () => import('../views/contractors/warranties/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Garantías',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_warranties.show'
        }
    },
    {
        path: '/contractors-warranties/:construction/new',
        name: 'ContractorsWarrantiesCreate',
        component: () => import('../views/contractors/warranties/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Nueva Garantía',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_warranties.create'
        }
    },
    {
        path: '/contractors-warranties/:construction/:slug/edit',
        name: 'ContractorsWarrantiesEdit',
        component: () => import('../views/contractors/warranties/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Editar Garantía',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_warranties.update'
        }
    },

    /**
     * ! Contractors ADDITIVES
    */
    // {
    //     path: '/contractors-additions/:construction',
    //     name: 'ContractorsAdditions',
    //     component: () => import('../views/contractors/additions/Index.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Egresos - Aditivas',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'business_permission_contractors.show|contractor_aditivas.show'
    //     }
    // },
    // {
    //     path: '/contractors-additions/:construction/new',
    //     name: 'ContractorsAdditionsCreate',
    //     component: () => import('../views/contractors/additions/Create.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Egresos - Nueva Aditiva',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'business_permission_contractors.show|contractor_aditivas.create'
    //     }
    // },
    // {
    //     path: '/contractors-additions/:construction/:slug/edit',
    //     name: 'ContractorsAdditionsEdit',
    //     component: () => import('../views/contractors/additions/Edit.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Egresos - Editar Aditiva',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'business_permission_contractors.show|contractor_aditivas.update'
    //     }
    // },

    /**
     * ! Contractors REDUCTIONS
    */
    {
        path: '/contractors-reductions/:construction',
        name: 'ContractorsReductions',
        component: () => import('../views/contractors/reductions/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Deductivas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_deductivas.show'
        }
    },
    {
        path: '/contractors-reductions/:construction/new',
        name: 'ContractorsReductionsCreate',
        component: () => import('../views/contractors/reductions/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Nueva Deductiva',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_deductivas.create'
        }
    },
    {
        path: '/contractors-reductions/:construction/:slug/edit',
        name: 'ContractorsReductionsEdit',
        component: () => import('../views/contractors/reductions/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Egresos - Editar Deductiva',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_contractors.show|contractor_deductivas.update'
        }
    },
];

const incomeConstructionRoutes = [
    /**
     * ! Income construction CXC
     */
    {
        path: '/income-construction-cxc/:construction',
        name: 'IncomeConstructionCXC',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/cxc/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Cuentas x cobrar',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_receivable_accounts.show'
        }
    },
    {
        path: '/income-construction-cxc/:construction/new',
        name: 'IncomeConstructionCXCCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/cxc/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Nueva Cuenta x cobrar',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_receivable_accounts.create'
        }
    },

    {
        path: '/income-construction-cxc/:construction/:slug/edit',
        name: 'IncomeConstructionCXCEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/cxc/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Editar Cuenta x cobrar',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_receivable_accounts.update'
        }
    },

    {
        path: '/income-construction-cxc/:construction/:slug/details',
        name: 'IncomeConstructionCXCDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/cxc/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Detalles Cuenta x cobrar',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_receivable_accounts.show'
        }
    },


    /**
     * ! Income construction Payments
     */
    {
        path: '/income-construction-estimations/:construction',
        name: 'IncomeConstructionPayments',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/payments/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Estimaciones',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_estimations.show'
        }
    },
    {
        path: '/income-construction-estimations/:construction/new',
        name: 'IncomeConstructionPaymentsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/payments/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Nueva Estimación',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_estimations.create'
        }
    },
    {
        path: '/income-construction-estimations/:construction/:slug/edit',
        name: 'IncomeConstructionPaymentsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/payments/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Editar Estimación',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_estimations.create'
        }
    },


    /**
     * ! Income construction Warranties
     */
    {
        path: '/income-construction-warranties/:construction',
        name: 'IncomeConstructionWarranties',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/warranties/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Garantías',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_warranties.show'
        }
    },
    {
        path: '/income-construction-warranties/:construction/new',
        name: 'IncomeConstructionWarrantiesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/warranties/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Nueva Garantía',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_warranties.show'
        }
    },
    {
        path: '/income-construction-warranties/:construction/:slug/edit',
        name: 'IncomeConstructionWarrantiesEdit',
        component: () => import('../views/incomeConstruction/warranties/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Editar Garantía',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_warranties.update'
        }
    },


    /**
     * ! Income construction Addition
     */
    // {
    //     path: '/income-construction-additions/:construction',
    //     name: 'IncomeConstructionAdditions',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/additions/Index.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Ingresos - Aditivas',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'business_permission_constructions.show|constructions_aditivas.show'
    //     }
    // },
    // {
    //     path: '/income-construction-additions/:construction/new',
    //     name: 'IncomeConstructionAdditionsCreate',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/additions/Create.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Ingresos - Nueva Aditiva',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'business_permission_constructions.show|constructions_aditivas.show'
    //     }
    // },

    // {
    //     path: '/income-construction-additions/:construction/:slug/edit',
    //     name: 'IncomeConstructionAdditionsEdit',
    //     component: () => import('../views/incomeConstruction/additions/Edit.vue'),
    //     meta: {
    //         parent:'',
    //         auth: true,
    //         title: 'Ingresos - Editar Aditiva',
    //         middlewares: {AuthMiddleware, VueGateMiddleware},
    //         permissions: 'business_permission_constructions.show|constructions_aditivas.update'
    //     }
    // },


    /**
     * ! Income construction Reductions
     */
    {
        path: '/income-construction-reductions/:construction',
        name: 'IncomeConstructionReductions',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/reductions/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Deductivas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_deductivas.show'
        }
    },
    {
        path: '/income-construction-reductions/:construction/new',
        name: 'IncomeConstructionReductionsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/incomeConstruction/reductions/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Nueva Deductiva',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_deductivas.show'
        }
    },

    {
        path: '/income-construction-reductions/:construction/:slug/edit',
        name: 'IncomeConstructionReductionsEdit',
        component: () => import('../views/incomeConstruction/reductions/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ingresos - Editar Deductiva',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_constructions.show|constructions_deductivas.update'
        }
    },
];
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

const permissionRoutes = [
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/users/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Usuarios',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_attributes.show|users.show'
        }
    },
    {
        path: '/users/new',
        name: 'UsersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/users/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo Usuario',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_attributes.show|users.create'
        }
    },
    {
        path: '/users/:slug/edit',
        name: 'UsersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/users/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Usuario',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_attributes.show|users.update'
        }
    },
    {
        path: '/users/:slug/details',
        name: 'UsersDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/users/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Detalles Usuario',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_attributes.show|users.show'
        }
    },

    /**
     * ! ROLES
     *  
    */
    {
        path: '/roles',
        name: 'Roles',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/roles/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Roles',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_attributes.show|roles.show'
        }
    },
    {
        path: '/roles/new',
        name: 'RolesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/roles/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo Rol',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_attributes.show|roles.create'
        }
    },
    {
        path: '/roles/:slug/edit',
        name: 'RolesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/permissions/roles/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Rol',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_attributes.show|roles.update'
        }
    },
];

const catalogRoutes = [
    


    
    /***
     * 
     * ! BUSINESS
     * 
    */
    {
        path: '/catalogs/business',
        name: 'Business',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/business/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Empresas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|business.show'
        }
    },
    {
        path: '/catalogs/business/new',
        name: 'BusinessCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/business/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva empresa',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|business.create'
        }
    },
    {
        path: '/catalogs/business/:slug/edit',
        name: 'BusinessEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/business/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar empresa',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|business.update'
        }
    },
    {
        path: '/catalogs/business/:slug/permissions',
        name: 'BusinessEditPermissions',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/business/EditPermissions.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar accesos de empresa',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|business.update_menu'
        }
    },

    /***
     * 
     * ! CLIENT
     * 
    */
    {
        path: '/catalogs/clients',
        name: 'Clients',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/customers/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Clientes',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|customers.show'
        }
    },
    {
        path: '/catalogs/clients/new',
        name: 'ClientsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/customers/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo cliente',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|customers.create'
        }
    },
    {
        path: '/catalogs/clients/:slug/edit',
        name: 'ClientsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/customers/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar cliente',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|customers.update'
        }
    },

    /***
     * 
     * ! PROVIDER
     * 
    */
    {
        path: '/catalogs/suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/suppliers/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Proveedores',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|providers.show'
        }
    },
    {
        path: '/catalogs/suppliers/new',
        name: 'SuppliersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/suppliers/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo proveedor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|providers.show'
        }
    },
    {
        path: '/catalogs/suppliers/:slug/edit',
        name: 'SuppliersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/suppliers/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar proveedor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|providers.show'
        }
    },

    /***
     * 
     * ! BANK
     * 
    */
    {
        path: '/catalogs/banks',
        name: 'Banks',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/banks/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Bancos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|banks.show'
        }
    },
    {
        path: '/catalogs/banks/new',
        name: 'BanksCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/banks/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo banco',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|banks.create'
        }
    },
    {
        path: '/catalogs/banks/:slug/edit',
        name: 'BanksEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/banks/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar banco',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|banks.update'
        }
    },

    /***
     * 
     * ! ACCOUNTS
     * 
    */
    {
        path: '/catalogs/accounts',
        name: 'Accounts',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/accounts/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Cuentas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|accounts.show'
        }
    },
    {
        path: '/catalogs/accounts/new',
        name: 'AccountsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/accounts/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva Cuenta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|accounts.create'
        }
    },
    {
        path: '/catalogs/accounts/:slug/edit',
        name: 'AccountsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/accounts/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Cuenta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|accounts.update'
        }
    },

    /***
     * 
     * ! CONCEPT
     * 
    */
    {
        path: '/catalogs/concepts',
        name: 'Concepts',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/concepts/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Sub Cuentas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|concepts.show'
        }
    },
    {
        path: '/catalogs/concepts/new',
        name: 'ConceptsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/concepts/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva Sub Cuenta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|concepts.create'
        }
    },
    {
        path: '/catalogs/concepts/:slug/edit',
        name: 'ConceptsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/concepts/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Sub Cuenta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|concepts.update'
        }
    },

    /***
     * 
     * ! REFERENCES
     * 
    */
    {
        path: '/catalogs/references',
        name: 'References',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/references/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - OT',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|references.show'
        }
    },
    {
        path: '/catalogs/references/new',
        name: 'ReferencesCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/references/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva OT',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|references.create'
        }
    },
    {
        path: '/catalogs/references/:slug/edit',
        name: 'ReferencesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/references/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar OT',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|references.update'
        }
    },

    /***
     * 
     * ! PAYMENT METHOD
     * 
    */
    {
        path: '/catalogs/payment-methods',
        name: 'PM',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/paymentMethods/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Formas de pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|payment_methods.show'
        }
    },
    {
        path: '/catalogs/payment-methods/new',
        name: 'PMCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/paymentMethods/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva Forma de pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|payment_methods.create'
        }
    },
    {
        path: '/catalogs/payment-methods/:slug/edit',
        name: 'PMEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/paymentMethods/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Forma de pago',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|payment_methods.update'
        }
    },

    /***
     * 
     * ! DEPOSIT WITHDRAW
     * 
    */
    {
        path: '/catalogs/deposit-withdraw',
        name: 'DW',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/deposits/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Sub sub cuentas',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|target_accounts.show'
        }
    },
    {
        path: '/catalogs/deposit-withdraw/new',
        name: 'DWCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/deposits/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva sub sub cuenta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|target_accounts.create'
        }
    },
    {
        path: '/catalogs/deposit-withdraw/:slug/edit',
        name: 'DWEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/deposits/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar sub sub cuenta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|target_accounts.update'
        }
    },


    /***
     * 
     * ! BANK ACCOUNTS
     * 
    */
    {
        path: '/catalogs/bank-accounts',
        name: 'BankAccounts',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/bankAccounts/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Cuentas bancarias',
            middlewares: {AuthMiddleware,VueGateMiddleware},
            permissions: 'business_permission_catalogs.show|bank_accounts.show'
        }
    },
    {
        path: '/catalogs/bank-accounts/new',
        name: 'BankAccountsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/bankAccounts/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nueva Cuenta bancaria',
            middlewares: {AuthMiddleware,VueGateMiddleware },
            permissions: 'business_permission_catalogs.show|bank_accounts.create'
        }
    },
    {
        path: '/catalogs/bank-accounts/:slug/edit',
        name: 'BankAccountsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/bankAccounts/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Cuenta bancaria',
            middlewares: {AuthMiddleware,VueGateMiddleware },
            permissions: 'business_permission_catalogs.show|bank_accounts.update'
        }
    },
    //! TRANSFERS
    {
        path: '/catalogs/bank-accounts/:bankAccount/transfers',
        name: 'BankAccountsTransfers',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/bankAccounts/transfer/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Traspasos',
            middlewares: {AuthMiddleware,VueGateMiddleware },
            permissions: 'business_permission_catalogs.show|transfers.show'
        }
    },
    {
        path: '/catalogs/bank-accounts/:bankAccount/transfers/new',
        name: 'BankAccountsTransfersCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/bankAccounts/transfer/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo - Traspaso',
            middlewares: {AuthMiddleware,VueGateMiddleware },
            permissions: 'business_permission_catalogs.show|transfers.create'
        }
    },
    {
        path: '/catalogs/bank-accounts/transfers/new',
        name: 'BankAccountsTransfersCreateIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/bankAccounts/transfer/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nuevo - Traspaso',
            middlewares: {AuthMiddleware,VueGateMiddleware },
            permissions: 'business_permission_catalogs.show|transfers.create'
        }
    },
    {
        path: '/catalogs/bank-accounts/:bankAccount/transfers/:transfer/edit',
        name: 'BankAccountsTransfersEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/bankAccounts/transfer/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Traspaso',
            middlewares: {AuthMiddleware,VueGateMiddleware },
            permissions: 'business_permission_catalogs.show|transfers.update'
        }
    },

    
];

const reportsRoutes = [
    /**
     * ! CXC
     */
    {
        path: '/reports/balance-due/receivable-accounts',
        name: 'DueCXC',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/balanceDue/CXC.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Saldo CXC',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|balance_due_ra.show'
        }
    },

    /**
     * ! CXP
     */
    {
        path: '/reports/balance-due/payable-accounts',
        name: 'DueCXP',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/balanceDue/CXP.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Saldo CXP',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|balance_due_pa.show'
        }
    },

    /**
     * ! balance due By Customers
     */
    {
        path: '/reports/balance/customer',
        name: 'ClientBalance',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/balanceDue/byCustomer.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Saldo x cliente',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|balance_due_customers.show'
        }
    },
    /**
     * ! balance due By Supplier
     */
    {
        path: '/reports/balance/supplier',
        name: 'SupplierBalance',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/balanceDue/bySupplier.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Saldo x proveedor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|balance_due_providers.show'
        }
    },

    /**
     * ! balance paid By Customer
    */
      {
        path: '/reports/balance/paid/customer',
        name: 'ClientsBalancePaid',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/balancePaid/byCustomer.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Pagos X cliente',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|balance_paid_customers.show'
        }
    },
    /**
     * ! balance paid By Suppler
    */
      {
        path: '/reports/balance/paid/supplier',
        name: 'SupplierBalancePaid',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/balancePaid/bySupplier.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Pagos X proveedor',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|balance_paid_providers.show'
        }
    },

    /**
     * ! Incomes range
     */
    {
        path: '/reports/incomes/period',
        name: 'PeriodIncome',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/incomePeriod/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Ingreso x periodo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|incomes_timelapse.show'
        }
    },
     /**
     * ! Other Incomes range
     */
     {
        path: '/reports/other-incomes',
        name: 'OtherIncome',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/otherIncomes/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Otros ingresos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|other_incomes_timerange.show'
        }
    },
    /**
     * ! Outcome range
     */
    {
        path: '/reports/outcome/period',
        name: 'PeriodOutcome',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/outcomePeriod/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Egreso x periodo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|outcomes_timelapse.show'
        }
    },
    /**
     * ! Payment concept
     */
    {
        path: '/reports/payments/concept',
        name: 'ConceptReport',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/payment/byConcept.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Reporte x cuenta',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|payments_concepts.show'
        }
    },
    /**
     * ! Payment bank account
     */
    {
        path: '/reports/payments/bank-accounts',
        name: 'AccountReport',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/payment/byAccount.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Conciliación bancaria',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|payments_bank_accounts.show'
        }
    },
    /**
     * ! CA Balance payable accounts
     */
    {
        path: '/reports/balance/ca-payable-accounts',
        name: 'CABalancePayableAccountReport', 
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/CAbalancePayableAccounts/Balance.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Cuentas de gastos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|balance_due_ca_pa.show'
        }
    },

   
    /**
     * ! Receivable timelapse
     */
    {
        path: '/reports/receivable-accounts/timelapse',
        name: 'ReportReceivableAccTimelapse',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/receivable/Timelapse.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Facturación x Periodo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|cxc_timelapse.show'
        }
    },

    /**
     * ! Receivable by customers
     */
    {
        path: '/reports/receivable-accounts/customers',
        name: 'ReportReceivableAccCustomers',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/receivable/ByCustomers.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Cobranza x Periodo',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|cxc_customers.show'
        }
    },

    /**
     * ! PAYMENT HISTORY
     */
    {
        path: '/reports/payments/history',
        name: 'PaymentHistory',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/payment/history.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Histórico pagos',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|reports_customers_payment_history.show'
        }
    },

    /**
     * ! CXC by folio (billings)
     */
    {
        path: '/reports/balance-due/billing',
        name: 'DueCXCByFolio',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/balanceDue/CXCByFolio.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Facturación',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|billing_receivable_accounts.show'
        }
    },

    /**
     * ! Loans pending
     */
    {
        path: '/reports/loans/pending',
        name: 'LoansPending',
        component: () => import(/* webpackChunkName: "about" */ '../views/reports/loans/Pending.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Deuda',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_reports.show|loans_pending.show'
        }
    },
];

const payrollRoutes = [
    /**
     * ! Payroll
     */
    {
        path: '/payroll',
        name: 'Payroll',
        component: () => import(/* webpackChunkName: "about" */ '../views/payroll/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Nómina',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_payroll.show|rrhh_payroll_payment.show'
        }
    },
    /**
     * ! Payroll employees
     */
    {
        path: '/payroll-employees',
        name: 'PayrollEmployees',
        component: () => import(/* webpackChunkName: "about" */ '../views/payroll/employees/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Empleados',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_payroll.show|rrhh_paysheet.show'
        }
    },
    {
        path: '/payroll-employees/:id/edit',
        name: 'PayrollEmployeesEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/payroll/employees/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Editar nómina',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_payroll.show|rrhh_paysheet.update'
        }
    },
    {
        path: '/payroll-employees/:id/details',
        name: 'PayrollEmployeesDetails',
        component: () => import(/* webpackChunkName: "about" */ '../views/payroll/employees/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Detalles nómina',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_payroll.show|rrhh_paysheet.show'
        }
    },

    /**
     * ! Annual budget
     */
    {
        path: '/annual-budget',
        name: 'AnnualBudget',
        component: () => import(/* webpackChunkName: "about" */ '../views/payroll/annualBudget/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Nóminas - Nómina',
            middlewares: {AuthMiddleware, VueGateMiddleware},
            permissions: 'business_permission_payroll.show|rrhh_reports_annual_budget.show'
        }
    },
];

export const routes = [
    ...initialRoutes,
    //...cgaaRoutes,
    ...cxpRoutes,
    ...cxcRoutes,
    ...invoiceRoutes,
    ...customAgentRoutes,
    ...contractorsRoutes,
    ...incomeConstructionRoutes,
    ...permissionRoutes,
    ...catalogRoutes,
    ...reportsRoutes,
    ...payrollRoutes,
    // ...loanRoutes,
    ...construnctionRoutes,
    ...summaryRoutes,
    
];