
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class PayableAccountService extends BaseApi {


    constructor(){
        super();
    }

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/payable-accounts${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/payable-accounts/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/payable-accounts`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/payable-accounts/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, id)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/payable-accounts/${id}`)
        .catch(this.handleErrorResponse);
    }


    async bySupplier(business, supplier)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/providers/${supplier}/payable-accounts`)
        .catch(this.handleErrorResponse);
    }

    async updateStatus(business, slug)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/payable-accounts/${slug}/change-status`)
        .catch(this.handleErrorResponse);
    }
   
   
    
    /***
     * 
     * 
     * ADVANCE CXP
     * 
     * 
    */
    
    async advanceList(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/payable-accounts/${slug}/payable-account-daughters`)
        .catch(this.handleErrorResponse);
    }


    async advancedStore(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/payable-accounts/${slug}/payable-account-daughters`, data)
        .catch(this.handleErrorResponse);
    }

    async advancedGet(business, slug, childSlug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/payable-accounts/${slug}/payable-account-daughters/${childSlug}`)
        .catch(this.handleErrorResponse);
    }

    async advancedUpdate(business, slug, childSlug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/payable-accounts/${slug}/payable-account-daughters/${childSlug}`, data)
        .catch(this.handleErrorResponse);
    }

    async advancedDelete(business, slug, childSlug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/payable-accounts/${slug}/payable-account-daughters/${childSlug}`)
        .catch(this.handleErrorResponse);
    }
}