
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class TransferService extends BaseApi {


    constructor(){
        super();
    }


    async byBankAccount(business, bankAccount, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/transfers/${bankAccount}/bank-accounts${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, transfer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/transfers/${transfer}`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/transfers`, data)
        .catch(this.handleErrorResponse);
    }

    async update(business, transfer, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/transfers/${transfer}`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, transfer)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/transfers/${transfer}`)
        .catch(this.handleErrorResponse);
    }
   

}