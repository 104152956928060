
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class IncomeCreditNoteService extends BaseApi {


    constructor(){
        super();
    }
    

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/income-credit-notes${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/income-credit-notes/${slug}`)
        .catch(this.handleErrorResponse);
    }

  

    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/income-credit-notes`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/income-credit-notes/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/income-credit-notes/${slug}`)
        .catch(this.handleErrorResponse);
    }



    async getSelectCreditNotesByCustomer(business, customer)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/customers/${customer}/credit-notes`)
        .catch(this.handleErrorResponse);
    }

}