
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class UtilService extends BaseApi {


    constructor(){
        super();
    }

    /**
     * GET
     * Get currency exchange
     * @param {string} exchange
     */
    async getExchange(exchange = "usd-mxn")
    {
        return this.http.get(`${this.baseUrl}/utils/exchange/${exchange}`)
        .catch(this.handleErrorResponse);
    }


}