
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class PaymentMethodService extends BaseApi {


    constructor(){
        super();
    }

    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/catalogs/payment-methods`)
        .catch(this.handleErrorResponse);
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/payment-methods`)
        .catch(this.handleErrorResponse);
    }

    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/payment-methods/${slug}`)
        .catch(this.handleErrorResponse);
    }



    async store(data)
    {
        return this.http.post(`${this.baseUrl}/payment-methods`, data)
        .catch(this.handleErrorResponse);
    }

    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/payment-methods/${slug}`,data)
        .catch(this.handleErrorResponse);
    }


    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/payment-methods/${slug}`)
        .catch(this.handleErrorResponse);
    }



}