import Vue from 'vue';
import Vuex from 'vuex';
import storePlugins from "@/plugins/storePlugins";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [storePlugins],
  state: {
    layout: 'loading',
    menu: true,
    loading: false,
    loggedIn: false,
    loggedUsername: 'U',
    userData: null,
    business: '',
    constructions:[],
    snackbarProps: {
      visible: false,
      bottom: false,
      color: 'success',
      left: false,
      multiline: true,
      right: true,
      timeout: 2000,
      top: true,
      vertical: false,
      text: ''
    },
    sideMenu:[
        {
            title: 'Dashboard',
            name: 'Dashboard',
            icon: 'mdi-view-dashboard',
            active: false,
            submenu: [],
            link: '/dashboard',
            permissions: 'dashboard.show',
            showMenuPermissions: 'business_permission_dashboard.show'
        },
        {
            title: 'Estado de resultados',
            name: 'Summary',
            icon: 'mdi-view-dashboard',
            active: false,
            submenu: [],
            link: '/summary',
            permissions: 'income_statement.show',
            showMenuPermissions: 'business_permission_income_statement.show'
        },
        
        {
            title: 'Ingresos', //cuentas por cobrar
            name: 'CXP',
            icon: 'mdi-cart',
            active: false,
            permissions: 'receivable_accounts.show|incomes.show|depositos.show|income_credit_notes.show|income_loans.show',
            showMenuPermissions: 'business_permission_cxc.show',
            submenu: [
                { title: 'Ventas', icon: 'mdi-star-face', name: 'CXC', link: '/cxc', permissions: 'receivable_accounts.show'}, //CXC
                { title: 'Cobros', icon: 'mdi-star-face', name: 'Incomes', link: '/incomes', permissions: 'incomes.show'}, //ingresos
                { title: 'Otros Ingresos', icon: 'mdi-star-face', name: 'Deposits', link: '/deposits', permissions: 'depositos.show'},
                { title: 'Notas de crédito', icon: 'mdi-star-face', name: 'CreditNotesIncomes', link: '/nc-income', permissions: 'income_credit_notes.show'},
                { title: 'Préstamos', icon: 'mdi-star-face', name: 'LoansIncome', link: '/loans-income', permissions: 'income_loans.show'},
            ]
        },
        {
            title: 'Egresos', //cuentas por pagar
            name: 'CXP',
            icon: 'mdi-cart',
            active: false,
            permissions: 'payable_accounts.show|outcomes.show|retiros.show|credit_notes.show|outcomes_loans.show',
            showMenuPermissions: 'business_permission_cxp.show',
            submenu: [
                { title: 'Compras', icon: 'mdi-star-face', name: 'CXP', link: '/cxp', permissions: 'payable_accounts.show'}, //cxp
                { title: 'Pagos', icon: 'mdi-star-face', name: 'Outcome', link: '/outcome', permissions: 'outcomes.show'}, //egresos
                { title: 'Otros Gastos', icon: 'mdi-star-face', name: 'Withdraws', link: '/withdraws', permissions: 'retiros.show'},
                { title: 'Notas de crédito', icon: 'mdi-star-face', name: 'CreditNotes', link: '/nc-outcome', permissions: 'credit_notes.show'},
                { title: 'Préstamos', icon: 'mdi-star-face', name: 'LoansOutcome', link: '/loans-outcome', permissions: 'outcomes_loans.show'},
            ]
        },
        // {
        //     title: 'Préstamos Inter.',
        //     name: 'Loans',
        //     icon: 'mdi-view-loans',
        //     active: false,
        //     submenu: [],
        //     link: '/loans',
        //     permissions: 'loans.show',
        //     showMenuPermissions: 'business_permission_loans.show'
        // },
        {
            title: 'Invoices',
            name: 'Invoice',
            icon: 'mdi-account-hard-hat',
            active: false,
            submenu: [],
            link: '/invoices',
            permissions: 'invoices.show',
            showMenuPermissions: 'business_permission_invoices.show',
        },
       
        {
            title: 'Agentes Aduanales',
            name: 'CustomAgents',
            icon: 'mdi-cart',
            active: false,
            permissions: 'pece_accounts.show|advances,show|demoras.show|warranties.show|ca_payable_accounts.show|payments.show|extraordinaries.show|ca_positive_balances.show|customs_agents.show',
            showMenuPermissions: 'business_permission_customs_agents.show',
            submenu: [
                { title: 'Anticipos', icon: 'mdi-star-face', name: 'Advances', link: '/advances', permissions: 'advances.show'},
                { title: 'Demoras', icon: 'mdi-star-face', name: 'Delays', link: '/delays', permissions: 'demoras.show'},
                { title: 'Garantías', icon: 'mdi-star-face', name: 'Warranties', link: '/warranties', permissions: 'warranties.show'},
                { title: 'Cuenta de gastos', icon: 'mdi-star-face', name: 'Advances', link: '/payable-accounts', permissions: 'ca_payable_accounts.show'},
                { title: 'Pagos', icon: 'mdi-star-face', name: 'Payments', link: '/payments', permissions: 'payments.show'},
                { title: 'Cuenta PECE', icon: 'mdi-star-face', name: 'PeceAccounts', link: '/pece-accounts', permissions: 'pece_accounts.show'},
                { title: 'Extraordinarios', icon: 'mdi-star-face', name: 'Extraordinaries', link: '/extraordinaries', permissions: 'extraordinaries.show'},
                { title: 'Saldos a favor', icon: 'mdi-star-face', name: 'PositiveBalance', link: '/positive-balance', permissions: 'ca_positive_balances.show'},
                { title: 'Agentes Aduanales', icon: 'mdi-star-face', name: 'CustomsAgents', link: '/customs-agents', permissions: 'customs_agents.show'},

            ]
        },
        {
            level:true,
            title: 'OBRAS',
            name: 'ConstructionsDynamic',
            icon: 'mdi-cart',
            active: false,
            permissions: 'constructions.show|construction-types.show|reports_contractors_cxc.show|contractors_balance.show|contractor_payable_accounts.show|contractor_payments.show|contractor_warranties.show|contractor_aditivas.show|contractor_deductivas.show|constructions_receivable_accounts.show|constructions_estimations.show|constructions_warranties.show|constructions_aditivas.show|constructions_deductivas.show',
            showMenuPermissions: 'business_permission_constructions_dynamic.show',
            submenu: [
                { title: 'Obras', icon: 'mdi-star-face', name: 'Constructions', link: '/catalogs/constructions', permissions: 'constructions.show',},
                { title: 'Tipos de obra', icon: 'mdi-star-face', name: 'ConstructionTypes', link: '/catalogs/construction-types', permissions: 'construction-types.show',},
                { title: 'Ingreso x obra', icon: 'mdi-star-face', name: 'ReportContractorsIncome', link: '/reports/balance-due/contractors/income', permissions: 'reports_contractors_cxc.show'},
                { title: 'Saldo x obra', icon: 'mdi-star-face', name: 'ReportContractorsBalance', link: '/reports/balance-due/contractors/balance', permissions: 'contractors_balance.show'},
            ],
            menu: [
                //
                //* DINAMIC MENUS
                //                
            ]
        },
        // {
        // 	title: 'Egresos x obra',
        // 	name: 'Contractors',
        // 	icon: 'mdi-cart',
        // 	active: false,
        //     permissions: 'contractor_payable_accounts.show|contractor_payments.show|contractor_warranties.show|contractor_aditivas.show|contractor_deductivas.show',
        //     showMenuPermissions: 'business_permission_contractors.show',
        // 	submenu: [
        // 		{ title: 'Contratos', icon: 'mdi-star-face', name: 'ContractorsCXP', link: '/contractors-cxp', permissions: 'contractor_payable_accounts.show'},
        // 		{ title: 'Estimaciones', icon: 'mdi-star-face', name: 'ContractorsPayments', link: '/contractors-estimations', permissions: 'contractor_payments.show'},
        // 		{ title: 'Garantías', icon: 'mdi-star-face', name: 'ContractorsWarranties', link: '/contractors-warranties', permissions: 'contractor_warranties.show'},
        // 		{ title: 'Aditivas', icon: 'mdi-star-face', name: 'ContractorsAdditionsCreate', link: '/contractors-additions', permissions: 'contractor_aditivas.show'},
        // 		{ title: 'Deductivas', icon: 'mdi-star-face', name: 'ContractorsReductions', link: '/contractors-reductions', permissions: 'contractor_deductivas.show'},
        // 	]
        // },
        // {
        // 	title: 'Ingresos x obra',
        // 	name: 'IncomeConstruction',
        // 	icon: 'mdi-cart',
        // 	active: false,
        //     permissions: 'constructions_receivable_accounts.show|constructions_estimations.show|constructions_warranties.show|constructions_aditivas.show|constructions_deductivas.show',
        //     showMenuPermissions: 'business_permission_constructions.show',
        // 	submenu: [
        // 		{ title: 'Cuentas x cobrar', icon: 'mdi-star-face', name: 'IncomeConstructionCXC', link: '/income-construction-cxc', permissions: 'constructions_receivable_accounts.show'},
        // 		{ title: 'Estimaciones', icon: 'mdi-star-face', name: 'IncomeConstructionPayments', link: '/income-construction-estimations', permissions: 'constructions_estimations.show'},
        // 		{ title: 'Garantías', icon: 'mdi-star-face', name: 'IncomeConstructionWarranties', link: '/income-construction-warranties', permissions: 'constructions_warranties.show'},
        // 		{ title: 'Aditivas', icon: 'mdi-star-face', name: 'IncomeConstructionAdditions', link: '/income-construction-additions', permissions: 'constructions_aditivas.show'},
        // 		{ title: 'Deductivas', icon: 'mdi-star-face', name: 'IncomeConstructionReductions', link: '/income-construction-reductions', permissions: 'constructions_deductivas.show'},
        // 	]
        // },
        {
            title: 'ATRIBUTOS',
            name: 'Permission',
            icon: 'mdi-cart',
            active: false,
            permissions: 'users.show|roles.show',
            showMenuPermissions: 'business_permission_attributes.show',
            submenu: [
                { title: 'Usuarios', icon: 'mdi-star-face', name: 'User', link: '/users', permissions: 'users.show'},
                { title: 'Roles', icon: 'mdi-star-face', name: 'Role', link: '/roles', permissions: 'roles.show'},
            ]
        },
        {
            title: 'Catálogos',
            name: 'Catalog',
            icon: 'mdi-cart',
            active: false,
            permissions: 'business.show|customers.show|providers.show|banks.show|accounts.show|concepts.show|references.show|payment_methods.show|target_accounts.show|bank_accounts.show',
            showMenuPermissions: 'business_permission_catalogs.show',
            submenu: [
                // { title: 'Obras', icon: 'mdi-star-face', name: 'Constructions', link: '/catalogs/constructions', permissions: 'constructions.show',},
                // { title: 'Tipos de obra', icon: 'mdi-star-face', name: 'ConstructionTypes', link: '/catalogs/construction-types', permissions: 'construction-types.show',},
                { title: 'Empresas', icon: 'mdi-star-face', name: 'Business', link: '/catalogs/business', permissions: 'business.show',},
                { title: 'Clientes', icon: 'mdi-star-face', name: 'Client', link: '/catalogs/clients', permissions: 'customers.show',},
                { title: 'Proveedores', icon: 'mdi-star-face', name: 'Suppliers', link: '/catalogs/suppliers', permissions: 'providers.show',},
                { title: 'Bancos', icon: 'mdi-star-face', name: 'Bank', link: '/catalogs/banks', permissions: 'banks.show',},
                { title: 'Cuentas', icon: 'mdi-star-face', name: 'Account', link: '/catalogs/accounts', permissions: 'accounts.show',},
                { title: 'Sub Cuentas', icon: 'mdi-star-face', name: 'Concept', link: '/catalogs/concepts', permissions: 'concepts.show',},
                { title: 'Sub sub cuentas', icon: 'mdi-star-face', name: 'DW', link: '/catalogs/deposit-withdraw', permissions: 'target_accounts.show'},
                { title: 'OT', icon: 'mdi-star-face', name: 'Reference', link: '/catalogs/references', permissions: 'references.show'},
                { title: 'Formas de pago', icon: 'mdi-star-face', name: 'PaymentMethod', link: '/catalogs/payment-methods',  permissions: 'payment_methods.show'},
                { title: 'Cuentas bancarias', icon: 'mdi-star-face', name: 'BankAccounts', link: '/catalogs/bank-accounts', permissions: 'bank_accounts.show'},
                
            ]
        },
        {
            title: 'Reportes',
            name: 'Report',
            icon: 'mdi-briefcase-account',
            active: false,
            permissions: 'balance_due_ra.show|balance_due_pa.show|balance_due_customers.show|balance_due_providers.show|incomes_timelapse.show|payments_concepts.show|payments_bank_accounts.show|balance_paid_customers.show|balance_paid_providers.show|cxc_timelapse.show|cxc_customers.show|outcomes_timelapse.show|reports_customers_payment_history.show|other_incomes_timerange.show',
            showMenuPermissions: 'business_permission_reports.show',
            submenu: [
                { title: 'Saldo CXC', icon: 'mdi-star-face', name: 'DueCXC', link: '/reports/balance-due/receivable-accounts', permissions: 'balance_due_ra.show'},
                { title: 'Saldo CXP', icon: 'mdi-star-face', name: 'DueCXP', link: '/reports/balance-due/payable-accounts', permissions: 'balance_due_pa.show'},
                { title: 'Saldo x cliente', icon: 'mdi-star-face', name: 'ClientBalance', link: '/reports/balance/customer', permissions: 'balance_due_customers.show'},
                { title: 'Saldo x proveedor', icon: 'mdi-star-face', name: 'SupplierBalance', link: '/reports/balance/supplier', permissions: 'balance_due_providers.show'},
                { title: 'Pagos x cliente', icon: 'mdi-star-face', name: 'ClientsBalancePaid', link: '/reports/balance/paid/customer', permissions: 'balance_paid_customers.show'},
                { title: 'Pagos x proveedor', icon: 'mdi-star-face', name: 'SupplierBalancePaid', link: '/reports/balance/paid/supplier', permissions: 'balance_paid_providers.show'},
                { title: 'Ingreso x periodo', icon: 'mdi-star-face', name: 'PeriodIncome', link: '/reports/incomes/period', permissions: 'incomes_timelapse.show'},
                { title: 'Otros ingresos', icon: 'mdi-star-face', name: 'OtherIncome', link: '/reports/other-incomes', permissions: 'other_incomes_timerange.show'},
                { title: 'Egreso x periodo', icon: 'mdi-star-face', name: 'PeriodIncome', link: '/reports/outcome/period', permissions: 'outcomes_timelapse.show'},
                { title: 'REPORTE X CUENTA', icon: 'mdi-star-face', name: 'ConceptReport', link: '/reports/payments/concept', permissions: 'payments_concepts.show'},
                { title: 'CONCILIACIÓN BANCARIA', icon: 'mdi-star-face', name: 'AccountReport', link: '/reports/payments/bank-accounts', permissions: 'payments_bank_accounts.show'},
                { title: 'Cuentas de gastos', icon: 'mdi-star-face', name: 'CABalancePayableAccountReport', link: '/reports/balance/ca-payable-accounts', permissions: 'balance_due_ca_pa.show'},
                
                
                { title: 'Facturación x periodo', icon: 'mdi-star-face', name: 'ReportReceivableAccTimelapse', link: '/reports/receivable-accounts/timelapse', permissions: 'cxc_timelapse.show'},
                { title: 'Cobranza x Periodo', icon: 'mdi-star-face', name: 'ReportReceivableAccCustomers', link: '/reports/receivable-accounts/customers', permissions: 'cxc_customers.show'},
                { title: 'Histórico pagos', icon: 'mdi-star-face', name: 'PaymentHistory', link: '/reports/payments/history', permissions: 'reports_customers_payment_history.show'},
                { title: 'Facturación', icon: 'mdi-star-face', name: 'DueCXCByFolio', link: '/reports/balance-due/billing', permissions: 'billing_receivable_accounts.show'},
                { title: 'Deuda', icon: 'mdi-star-face', name: 'LoansPending', link: '/reports/loans/pending', permissions: 'loans_pending.show'},
            ]
        },
        {
            title: 'Nómina',
            name: 'Payroll',
            icon: 'mdi-cart',
            active: false,
            permissions: 'rrhh_payroll_payment.show|rrhh_paysheet.show|rrhh_reports_annual_budget.show',
            showMenuPermissions: 'business_permission_payroll.show',
            submenu: [
                { title: 'Empleados', icon: 'mdi-star-face', name: 'PayrollEmployees', link: '/payroll-employees', permissions: 'rrhh_paysheet.show'},
                { title: 'Nómina', icon: 'mdi-star-face', name: 'Payroll', link: '/payroll', permissions: 'rrhh_payroll_payment.show'},
                { title: 'Presupuesto anual', icon: 'mdi-star-face', name: 'AnnualBudget', link: '/annual-budget', permissions: 'rrhh_reports_annual_budget.show'},
            ]
        },
    ]
  },
  mutations: {
  },
  actions: {
    tstAction(){
      console.log("aa");
      //router.push('/about');
    },

    /**
     * Open snackbar with success status
     * @param {*} _ dispatch data
     * @param {string} message Message to show
     */
    snackbarSuccess(_,message){
      this.state.snackbarProps.visible = true
      this.state.snackbarProps.color = 'success'
      this.state.snackbarProps.text = message;
    },

    /**
     * Open snackbar with error status
     * @param {*} _ dispatch data
     * @param {string} message Message to show
     */
     snackbarError(_,message){
      this.state.snackbarProps.visible = true
      this.state.snackbarProps.color = 'error'
      this.state.snackbarProps.text = message;
    },
    // update(){},

    addSideItem(_,data = {value: "", text: ""})
    {
        let parent = this.state.sideMenu.find((item) => item.name == "ConstructionsDynamic");
        parent.menu.push(
            { 
                level:true,
                title: data.text,
                icon: 'mdi-star-face', 
                name: data.value, 
                active:false,
                permissions: 'contractor_payable_accounts.show|contractor_payments.show|contractor_warranties.show|contractor_aditivas.show|contractor_deductivas.show|constructions_receivable_accounts.show|constructions_estimations.show|constructions_warranties.show|constructions_aditivas.show|constructions_deductivas.show',
                showMenuPermissions: 'business_permission_contractors.show|business_permission_constructions.show',
                menu:[
                    {
                        level:false,
                        title: 'Egresos', 
                        icon: 'mdi-star-face', 
                        active: false,
                        name: 'Contractors', 
                        permissions: 'contractor_payable_accounts.show|contractor_payments.show|contractor_warranties.show|contractor_deductivas.show',
                        showMenuPermissions: 'business_permission_contractors.show|contractor_payable_accounts.show',
                        submenu:[
                            
                            { title: 'Contratos', icon: 'mdi-star-face', name: 'ContractorsCXP', link: `/contractors-cxp/${data.value}`, permissions: 'contractor_payable_accounts.show'},
                            { title: 'Estimaciones', icon: 'mdi-star-face', name: 'ContractorsPayments', link: `/contractors-estimations/${data.value}`, permissions: 'contractor_payments.show'},
                            { title: 'Garantías', icon: 'mdi-star-face', name: 'ContractorsWarranties', link: `/contractors-warranties/${data.value}`, permissions: 'contractor_warranties.show'},
                            // { title: 'Aditivas', icon: 'mdi-star-face', name: 'ContractorsAdditionsCreate', link: `/contractors-additions/${data.value}`, permissions: 'contractor_aditivas.show'},
                            { title: 'Deductivas', icon: 'mdi-star-face', name: 'ContractorsReductions', link: `/contractors-reductions/${data.value}`, permissions: 'contractor_deductivas.show'},
                            
                        ]
                    },
                    {
                        level:false,
                        title: 'Ingresos', 
                        icon: 'mdi-star-face', 
                        active: false,
                        name: 'IncomeConstruction', 
                        permissions: 'constructions_receivable_accounts.show|constructions_estimations.show|constructions_warranties.show|constructions_deductivas.show',
                        showMenuPermissions: 'business_permission_constructions.show',
                        submenu:[
                            
                            { title: 'Cuentas x cobrar', icon: 'mdi-star-face', name: 'IncomeConstructionCXC', link: `/income-construction-cxc/${data.value}`, permissions: 'constructions_receivable_accounts.show'},
                            { title: 'Estimaciones', icon: 'mdi-star-face', name: 'IncomeConstructionPayments', link: `/income-construction-estimations/${data.value}`, permissions: 'constructions_estimations.show'},
                            { title: 'Garantías', icon: 'mdi-star-face', name: 'IncomeConstructionWarranties', link: `/income-construction-warranties/${data.value}`, permissions: 'constructions_warranties.show'},
                            // { title: 'Aditivas', icon: 'mdi-star-face', name: 'IncomeConstructionAdditions', link: `/income-construction-additions/${data.value}`, permissions: 'constructions_aditivas.show'},
                            { title: 'Deductivas', icon: 'mdi-star-face', name: 'IncomeConstructionReductions', link: `/income-construction-reductions/${data.value}`, permissions: 'constructions_deductivas.show'},
                            
                        ]
                    },
                    
                ]
            },
        );
        parent.menu.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.state.constructions.push({value: data.value, name: data.text})
    },

    removeSideitem(_, value)
    {
        let parent = this.state.sideMenu.find((item) => item.name == "ConstructionsDynamic");
        let foundIndex = parent.menu.findIndex((item) => item.name == value);
        let foundIndexStore = this.state.constructions.findIndex((item) => item.value == value);
        // console.log(foundIndex)
        if(foundIndex >= 0)
            parent.menu.splice(foundIndex, 1);

        if(foundIndexStore >= 0)
            this.state.constructions.splice(foundIndexStore, 1);
    }


  },
  modules: {
  }
})
