<template>
  <div v-if="!props.loading">
    <v-data-table
        v-model="value"
        :sort-by.sync="props.sortBy"
        :sort-desc.sync="props.sortDesc" 
        :loading="props.loading" 
        :class="`elevation-${props.elevation??'3'} ${props.loading ? '': 'table-b-border-header'}`" 
        
        :options.sync="opts"
        
        :single-expand="props.singleExpand" 
        :expanded.sync="props.expandSync" 
        :show-expand="props.showExpand" 
        no-results-text="No hay resultados" 
        no-data-text="No hay datos" 
        :headers="props.headers" 
        :items="props.items" 
        :search="props.search"
        :item-key="props.key"
        
        :hide-default-footer="props.hideFooter" 
        :show-select="props.showSelect"
        :disable-pagination="props.hideFooter" 
        :footer-props="{
        itemsPerPageOptions:[13, 25, 50, 100]
        }">
        <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top v-for="(action, i) of item.actions" :key="i" v-permission="action.permissions??'all'">
                <template v-slot:activator="{ on }">
                    <v-btn :loading="action.loading" x-small v-if="action.type === 'method'" class="mx-0" icon @click="methodHandler(action.action, item.parameters)" :disabled="action.disabled">
                        <v-icon :color="action.icon.color" v-on="on" :class="action.icon.class">{{action.icon.icon}}</v-icon>
                    </v-btn>
                    <v-btn :loading="action.loading" x-small v-else-if="action.type === 'redirect'" icon text :to="action.action" :disabled="action.disabled">
                        <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                    <v-btn :loading="action.loading" x-small v-else-if="action.type === 'external'" icon text target="_blank" :href="action.action" :disabled="action.disabled">
                        <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ action.name }}</span>
            </v-tooltip>
        </template>
        <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope"
        /></template>
    </v-data-table>
  </div>
  <div v-else>
     <v-data-table
       
        :loading="props.loading" 
        :class="`elevation-${props.elevation??'3'} ${props.loading ? '': 'table-b-border-header'}`" 
        :single-expand="props.singleExpand" 
        :expanded.sync="props.expandSync" 
        :show-expand="props.showExpand" 
        :headers="props.headers" 
        :hide-default-footer="props.hideFooter" 
        :show-select="props.showSelect"
        :disable-pagination="props.hideFooter" 
        :footer-props="{
        itemsPerPageOptions:[13, 25, 50, 100]
        }"></v-data-table>

  </div>
</template>

<script>
    export default {
       
        watch: {
            
            opts: {
                handler() {
                   this.handleOptions()
                },
                deep: true,
            },
        },
        
        name: 'datatable',
        props: ['props', 'valueInput', 'options'],
        model: {
            prop: 'value',
            event: 'valueChange'
        },
        computed: {
            value: {
                get: function() {
                    return this.valueInput
                },
                set: function(value) {
                    console.log(value)
                    this.$emit('valueChange', value)
                }
            }
        },
        data() {
            return{
                opts: this.options,
               
            }
        },
        setup() {
            // console.log(this.options, "setup")
            // const state = {
            //     page: 2
            // }
            // return { state }
        },
        
       
        methods: {
            methodHandler(methodName, parameters){
                console.log(55)
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
        
            handleOptions(){
               
                console.log("settted options")
                this.$emit('handleOptions', {
                    options: this.opts
                })
                
              
            }
            
        }
    }
</script>

<style>

</style>