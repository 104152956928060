<template>
    <div>
        <div class="alternative__container rounded">
           
            
            <div class="alternative__input">
                
                <v-text-field
                    
                    :placeholder="placeholder"
                    color="black"
                    v-model="value" 
                    hide-details="auto" 
                    solo
                    flat
                    dense 
                    
                    background-color='transparent'
                   
                    class="body-2 form-input"
                    :rules="rule"
                    :append-icon="prependInnerIcon"
                    
                    :error-messages="errorMessages"
                    :clearable="clearable"
                    :readonly="readonly"
                    @change="onChange"
                    @click:clear="onClear"
                ></v-text-field>

            </div>
        </div>
       
        <v-progress-linear color="primary" indeterminate  height="2" v-if="loading"></v-progress-linear>
    </div>
    
</template>
<script>
    export default {
        props: ['valueInput', 'rule', 'label', 'items', 'loading', 'disabled', 'placeholder', 'prependInnerIcon', 'readonly', 'clearable', 'errorMessages'],
        computed: {
            value: {
                get: function() {
                    return this.valueInput
                },
                set: function(value) {
                    this.$emit('valueChange', value)
                }
            },
       
        
        },
        methods:{
            onChange(val){
                this.$emit('onChanged', val)
            },
            onClear(){
                this.$emit('onClear', "")
            }
        }
    }
    
</script>

<style lang="scss">


    .alternative{
        &__container{
            //padding-left: 10px;
            border: $lineColor-1 1px solid;
            display: flex;
            // align-items: center;
            
        }

        &__label{
            cursor: default;
            min-width: fit-content;
            &--text{
                //font-weight: bold !important;

            }
        }

        &__input{
            // margin-left: 10px;
            width: -webkit-fill-available;
           
        }
    }

    .form-input{
        width: 100%;
        .v-input__control .v-input__slot input{
            //margin-bottom: 8px;
            margin-top: 0px !important;
            padding: 0;
        }
    }

   
</style>