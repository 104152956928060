
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class DashboardService extends BaseApi {


    constructor(){
        super();
    }



    async incomeByCustomer(business, date, page = 1, currency )
    {
        return this.http.post(`${this.baseUrl}/business/${business}/dashboard/incomes-per-customer?page=${page}`,
        {
            "start_date": date[0],
            "end_date":date[1],
            "currency":currency
        }).catch(this.handleErrorResponse);
    }

    async salesByCustomer(business, date, page = 1, currency )
    {
        return this.http.post(`${this.baseUrl}/business/${business}/dashboard/sales-per-customer?page=${page}`,
        {
            "start_date": date[0],
            "end_date":date[1],
            "currency":currency
        }).catch(this.handleErrorResponse);
    }

    async outcomeByConcept(business, date, page = 1, currency )
    {
        return this.http.post(`${this.baseUrl}/business/${business}/dashboard/outcomes-per-concept?page=${page}`,
        {
            "start_date": date[0],
            "end_date":date[1],
            "currency":currency
        }).catch(this.handleErrorResponse);
    }


    async expensesBySupplier(business, date, page = 1, currency )
    {
        return this.http.post(`${this.baseUrl}/business/${business}/dashboard/expenses-per-provider?page=${page}`,
        {
            "start_date": date[0],
            "end_date":date[1],
            "currency":currency
        }).catch(this.handleErrorResponse);
    }

    async purchasesBySupplier(business, date, page = 1, currency )
    {
        return this.http.post(`${this.baseUrl}/business/${business}/dashboard/purchases-per-provider?page=${page}`,
        {
            "start_date": date[0],
            "end_date":date[1],
            "currency":currency,
            "per_page": "3"
        }).catch(this.handleErrorResponse);
    }

}