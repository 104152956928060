import {$api} from "@/services";
import {$banxicoSIE} from "@/services/external"


export default function(store) {
    try {
      store.$api = $api;
      store.$banxicoSIE = $banxicoSIE;
    } catch (e) {
      console.error(e);
    }
}