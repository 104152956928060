
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class DepositService extends BaseApi {


    constructor(){
        super();
    }

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/movements/incomes/type${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/movements/${slug}`)
        .catch(this.handleErrorResponse);
    }

   

    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/movements`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/movements/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/movements/${slug}`)
        .catch(this.handleErrorResponse);
    }

    pdfDetails(business, id)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/movements/${id}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


}