<template>
	<v-navigation-drawer :key="updateSide" v-if="!loadingConstructions" v-model="$store.state.menu" width="220"  app color="sidebarPrimary" class="body-2 sidemenu-border left-direction">
		<v-list-item class="mt-15 mb-15">
			<v-list-item-content class="justify-center">
				<v-img src="./../assets/logo-merca.svg" contain max-width="100%" :class="$vuetify.theme.dark ? 'icon-white' : 'icon-white' "></v-img>
			</v-list-item-content>
		</v-list-item>

		<v-list dense tile flat dark class="right-direction mb-15">
            
            
			<div v-for="(modulo, i) in menu" :key="i" v-permission:any="modulo.showMenuPermissions??'all'">
                <div v-if="!modulo.level" >
                    <v-list-group  v-if="modulo.submenu.length > 0" v-model="modulo.active" v-permission:any="modulo.permissions??'all'">
                        <template v-slot:activator >
                            <v-list-item-title class="list-item side-font-size">{{ modulo.title }}</v-list-item-title>
                        </template>
                        
                        <v-list-item v-for="(submodulo, j) in modulo.submenu" :key="j" :to="submodulo.link" :inactive="submodulo.link === null" class="list-item" active-class="active-list-item" v-permission:any="submodulo.permissions??'all'">
                            <!-- <v-list-item-icon><v-icon>{{ submodulo.icon }}</v-icon></v-list-item-icon> -->
                            <v-list-item-title class="side-font-size">{{ submodulo.title }}</v-list-item-title>						
                        </v-list-item>
                    </v-list-group>

                    <v-list-item v-permission:any="modulo.permissions??'all'" :to="modulo.link" v-else :inactive="modulo.link === null" class="list-item" active-class="active-list-item">
                        <!-- <v-list-item-icon><v-icon>{{ modulo.icon }}</v-icon></v-list-item-icon> -->
                        <v-list-item-title class="side-font-size">{{ modulo.title }}</v-list-item-title>
                    </v-list-item>
                </div>

                <div v-else>
                    <v-list-group  v-model="modulo.active" v-permission:any="modulo.permissions??'all'">

                        <template v-slot:activator >
                            <v-list-item-title class="list-item side-font-size">{{modulo.title}}</v-list-item-title>
                        </template>

                        <!-- catalog link -->
                        <v-list-item 
                            v-for="(submodulo) in modulo.submenu" 
                            :key="submodulo.name" 
                            :to="submodulo.link" 
                            :inactive="submodulo.link === null" 
                            class="list-item list-item-special-1" 
                            active-class="active-list-item" 
                            v-permission:any="submodulo.permissions??'all'" 
                            >
                            <v-list-item-title class="side-font-size">{{submodulo.title}}</v-list-item-title>						
                        </v-list-item>

                        <!-- Rest modules -->
                        <v-list-group
                            sub-group 
                            no-action 
                            @click="() => {closeOthers(modulo.menu, menuLevel2.name)}"
                            :value="false" 
                            v-model="menuLevel2.active"
                            prepend-icon="" 
                            v-for="(menuLevel2) in modulo.menu" :key="menuLevel2.name"
                            v-permission:any="menuLevel2.showMenuPermissions??'all'"
                            >
                            <template v-slot:appendIcon >
                                <v-icon>mdi-chevron-down</v-icon>
                            </template>
                            <template v-slot:activator >
                                <v-list-item-content>
                                    <v-list-item-title class="list-item side-font-size ">{{menuLevel2.title}}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            
                   
                            <v-list-group  
                                sub-group 
                                no-action 
                                :value="false"
                                @click="() => {closeOthers(menuLevel2.menu, menuLevel3.name)}"
                                 v-model="menuLevel3.active"
                                v-for="(menuLevel3) in menuLevel2.menu" :key="menuLevel3.name"
                                v-permission:any="menuLevel3.showMenuPermissions??'all'"
                                prepend-icon="" 
                                >
                                <template v-slot:appendIcon >
                                    <v-icon>mdi-chevron-down</v-icon>
                                </template>
                                <template v-slot:activator >
                                    <v-list-item-content>
                                        <v-list-item-title class="list-item side-font-size list-item-level-2">{{menuLevel3.title}}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                
                                <v-list-item 
                                    v-for="(submodulo) in menuLevel3.submenu" 
                                    :key="submodulo.name" 
                                    :to="submodulo.link" 
                                    :inactive="submodulo.link === null" 
                                    class="list-item list-subitem" 
                                    active-class="active-list-item" 
                                    v-permission:any="submodulo.permissions??'all'" 
                                    >
                                    <!-- <v-list-item-icon><v-icon>{{ submodulo.icon }}</v-icon></v-list-item-icon> -->
                                    <v-list-item-title to="Dashboard" class="side-font-size">{{submodulo.title}}</v-list-item-title>						
                                </v-list-item>
                            </v-list-group>
                        </v-list-group>
                    </v-list-group>
                </div>
				
			</div>
		</v-list>
        <div class="triangle triangle-1"></div>
        <div class="triangle triangle-2"></div>
        <div class="triangle triangle-3"></div>
        <div class="triangle triangle-4"></div>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: 'Sidemenu',
		watch: {
			$route(to) {
				if (to.matched.length >= 1) {
					const parent = to.matched.filter(m => !m.parent)[0];
					const route = this.menu.filter(route => route.name === parent.meta.parent)[0];
					if(route != undefined)
						route.active = true;
				}
			},
            // `this.$store.state`(v) {
			// 	console.log('ok', v);
			// },
           
		},
		data () {
			return {
                updateSide:83932,
                loadingConstructions:true,
				menu: this.$store.state.sideMenu,
				right: null,
			}
		},
        mounted() {
            // console.log(this.$store.state.business)
            this.loadConstructionMenu();
        },
		methods: {
            closeOthers(menu, name)
            {
               
                for (let i = 0; i < menu.length; i++) {
                   
                    if(menu[i].name != name)
                        menu[i].active = false;
                }
            },
			loadConstructionMenu(){
                this.loadingConstructions = true;
                this.$api.construction.getSelect(this.$store.state.business)
                    .then((resp) => {
                        this.$store.state.constructions = resp.data;

                        // let parent = this.menu.find((item) => item.name == "ConstructionsDynamic");
                        

                        resp.data.forEach(c => {
                            this.$store.dispatch('addSideItem', {text: c.name, value: c.value});
                            
                        });


                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loadingConstructions = false;
                    })
                ;
            }
		},

	};
</script>
<style lang="scss">
	.v-list-group__items .v-list-item {
		padding-left: 30px;
        
	}

    .list-item-special-1{
        padding-left: 25px !important;
    }

    .list-subitem{
        padding-left: 50px !important;
    }

    .side-font-size{
        font-size: .77rem !important;
    }

    .list-item{
        color: var(--v-sidebarText-base) !important;
       
        &-level-2{
            padding-left: 10px;
        }
        &-level-3{
            padding-left: 15px;
        }
    }

    .active-list-item{
       
        background-color: var(--v-primary-base);
        color: var(--v-sidebarTextActive-base) !important;
    }

    .v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child), .v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child){
        margin-left: unset !important;
    }
    .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{
        min-width: unset !important;
    }

	.v-list-group__items .v-list-item.border-orange {
		padding-left: 24px !important;
	}

	.v-list-item.border-orange{
		padding-left: 10px !important;
	}

	.v-list--dense .v-list-item .v-list-item__icon{
		margin-top: 7px;
		margin-bottom: 7px;
	}

    .sidemenu-border{
        -webkit-border-radius: 0px 70px 70px 0px;
        -moz-border-radius: 0px 70px 70px 0px;
        border-radius: 0px 70px 70px 0px;
    }

    .right-direction{
        direction: ltr !important;
    }

    .left-direction{
        direction: rtl !important;
    }


    .triangle{
        width: 100%;
        height: 350px;
        background: #555;
        position: absolute;
        bottom: 0px;
        left: 220px;
        z-index: -10;
		
        &::after{
            content: "";
            width: 400%;
            height: 400px;
            position: absolute;
            left: -250%;
            z-index: -1;
            -webkit-clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);
            clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);

			opacity: 0;
			animation-name: triangeMoveInbuttom;
			animation-duration: 1s;
			animation-fill-mode: forwards;  
			animation-timing-function: ease-out; /*como va manejar el tiempo de la animacion (esase-out: se acelera al final)*/
    
        }

        &-1{
            &::after{
                background: var(--v-sidebarDetail1-base);
                bottom: -40%;
				animation-delay: 1.2s;
            }
			
        }

        &-2{
            &::after{
                background: var(--v-sidebarDetail2-base);;
                bottom: -55%;
				animation-delay: 0.8s;
            }
        }

        &-3{
            &::after{
                background: var(--v-sidebarDetail3-base);
                bottom: -70%;
				animation-delay: 0.4s;
            }
        }


        &-4{
            &::after{
                background: var(--v-sidebarDetail4-base);;
                bottom: -85%;
            }
        }

    }



</style>