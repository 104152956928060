
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ConceptService extends BaseApi {


    constructor(){
        super();
    }
    

    async list(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/concepts`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/concepts/${slug}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET
     * Get concepts for selectors
     * @param {string} business business slug
     */
    async getSelectConcepts(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/catalogs/concepts`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/concepts`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/concepts/${slug}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/concepts/${slug}`)
        .catch(this.handleErrorResponse);
    }


}