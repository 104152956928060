import BaseApi from "./BaseApi";

export default class ConstructionService extends BaseApi {


    constructor(){
        super();
    }
    

    async list(business, filter)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/constructions${filter}`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/constructions/${slug}`)
        .catch(this.handleErrorResponse);
    }

    async getSelect(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/catalogs/constructions`)
        .catch(this.handleErrorResponse);
    }


    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/constructions`, data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/constructions/${slug}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/constructions/${slug}`)
        .catch(this.handleErrorResponse);
    }


}