
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class BankService extends BaseApi {


    constructor(){
        super();
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/catalogs/banks`)
        .catch(this.handleErrorResponse);
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/banks`)
        .catch(this.handleErrorResponse);
    }

    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/banks/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async store(data)
    {
        return this.http.post(`${this.baseUrl}/banks`,data)
        .catch(this.handleErrorResponse);
    }

    async update(slug, data)
    {
        return this.http.put(`${this.baseUrl}/banks/${slug}`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/banks/${slug}`)
        .catch(this.handleErrorResponse);
    }



    /**
     * 
     * 
     * ! BANK ACCONTS
     * 
     * 
    */
    async accountGetSelect(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/catalogs/bank-accounts`)
        .catch(this.handleErrorResponse);
    }
    
    async accountList(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/bank-accounts`)
        .catch(this.handleErrorResponse);
    }

    async accountGet(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/bank-accounts/${slug}`)
        .catch(this.handleErrorResponse);
    }


    async accountStore(business,data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/bank-accounts`, data)
        .catch(this.handleErrorResponse);
    }


    async accountUpdate(business, slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/bank-accounts/${slug}`, data)
        .catch(this.handleErrorResponse);
    }

    async accountDelete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/bank-accounts/${slug}`)
        .catch(this.handleErrorResponse);
    }
   

}