
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class SupplierService extends BaseApi {


    constructor(){
        super();
    }

    async permissionList(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/providers/permissions`)
        .catch(this.handleErrorResponse);
    }

    async list(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/providers`)
        .catch(this.handleErrorResponse);
    }

    async get(business, slug)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/providers/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }

    async getReport(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/providers/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    async store(business, data)
    {
        return this.http.post(`${this.baseUrl}/business/${business}/providers`,data)
        .catch(this.handleErrorResponse);
    }


    async update(business, slug, data)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/providers/${slug}`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(business, slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${business}/providers/${slug}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET
     * Get providers for selectors
     * @param {string} business business slug
     */
    async getSelectSuppliers(business)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/catalogs/providers`)
        .catch(this.handleErrorResponse);
    }


    async getSelectPastDueBalance(business, search = '', page = 1)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/list/providers?search=${search}&page=${page}`)
    }



    async updatePermission(business, slug)
    {
        return this.http.put(`${this.baseUrl}/business/${business}/providers/permissions/${slug}`)
        .catch(this.handleErrorResponse);
    }

    async contractorPayableAccounts(business, construction, supplier)
    {
        return this.http.get(`${this.baseUrl}/business/${business}/providers/${supplier}/constructions/${construction}/payable-accounts`)
        .catch(this.handleErrorResponse);
    }
  


}