import TokenManager from "../helpers/TokenManager";
import store from "../store";
// import Vue from 'vue';
import VueGatesPlugin from '../plugins/vue-gates';

export default function AuthMiddleware({to,next}) {

    //console.log('authmiddle')
    // const tokenManager = new TokenManager();
    let token = TokenManager.getToken();
  
    if(token !== null && store.state.loggedIn){
        if(to.path == '/login'){
            next('/');
        }
        store.state.layout = 'dashboard'
        document.title = to.meta.title;
        
        next();
    }else{
        if(token !== null){
            store.$api.auth.me()
            .then((response) => {
                //$gates.setRoles(['writer']);
               
                if(!response.data){
                    store.state.loggedIn = false
                }
                else{
                   // console.log(response, 'ME');
                    store.state.loggedIn = true
                    store.state.loggedUsername = response.data.name;
                    store.state.userData = response.data;
                    store.state.userData.business = response.data.business.sort(function (a, b) {
                        if (a.name > b.name) {
                            return 1;
                        }
                        if (a.name < b.name) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                    VueGatesPlugin.init(response.data);
                    //console.log('here')
                }
                
                if(store.state.loggedIn){
                    if(to.meta.auth){
                        store.state.layout = 'dashboard'
                        document.title = to.meta.title
                        next();
                    }else{
                        store.state.layout = 'dashboard'
                        next('/');
                    }
                }else{
                    if(to.meta.auth){
                        store.state.layout = 'login'
                        next('login');
                    }else{
                        store.state.layout = 'login'
                        next()
                    }
                }
                
            })
            .catch((error) => {
              console.log(error, to)
           
                  TokenManager.removeToken();
                  store.state.layout = 'login'
                  next('login')
                  return;
           
            })
        }else{
            if(to.meta.auth){
                store.state.layout = 'login'
                next('login')
            }else{
                store.state.layout = 'login'
                next()
            }
        }
    }
}


