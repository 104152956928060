
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class BusinessService extends BaseApi {


    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/business`)
        .catch(this.handleErrorResponse);
    }

    async getSelectAll()
    {
        return this.http.get(`${this.baseUrl}/catalogs/business-all`)
        .catch(this.handleErrorResponse);
    }

    async getSelectTradeName()
    {
        return this.http.get(`${this.baseUrl}/catalogs/business/trade-name`)
        .catch(this.handleErrorResponse);
    }

    async get(slug)
    {
        return this.http.get(`${this.baseUrl}/business/${slug}/edit`)
        .catch(this.handleErrorResponse);
    }

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/business`, data)
        .catch(this.handleErrorResponse);
    }

    async update(slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${slug}/update`, data)
        .catch(this.handleErrorResponse);
    }

    async updateMenu(slug, data)
    {
        return this.http.post(`${this.baseUrl}/business/${slug}/update-menu`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(slug)
    {
        return this.http.delete(`${this.baseUrl}/business/${slug}`)
        .catch(this.handleErrorResponse);
    }


}