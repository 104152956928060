
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class BalanceService extends BaseApi {


    constructor(){
        super();
    }

    async readXML(file)
    {
        return this.http.post(`${this.baseUrl}/balances/readXML`,file,{headers: { "Content-Type": "multipart/form-data"}})
        .catch(this.handleErrorResponse);
    }

    async readXMLCommission(file)
    {
        return this.http.post(`${this.baseUrl}/utils/read-xml/commissions`,file,{headers: { "Content-Type": "multipart/form-data"}})
        .catch(this.handleErrorResponse);
    }


}