import Vue from 'vue';
import store from "../store";
import Permissions from '../views/permissions/roles/permission';



export default class VueGatesPlugin{
    static init(dataInput = null)
    {   
        
        // console.log(Vue.prototype.$gates.getRoles())
        // if(Vue.prototype.$gates.getRoles().length != 0)
        //     return;
        Vue.prototype.$gates.setRoles([]);
        Vue.prototype.$gates.setPermissions([]);
        let user = dataInput?? store.state.userData;
        Vue.prototype.$gates.setRoles([user.role.name]);
        // console.log(Vue.prototype.$gates.getRoles(), store.state.userData);
        let actions = ['all'];
        for (const property in user.role.permissions) {
            if(property == 'root' && user.role.permissions[property] == true ){
                for (const property in Permissions) {
                    if(property != 'root'){
                        actions.push(`${property}.show`);
                        actions.push(`${property}.create`);
                        actions.push(`${property}.update`);
                        actions.push(`${property}.delete`);

                        //unique permissions
                        if(property == "rrhh_payroll_payment")
                            actions.push(`${property}.save`);
                        if(property == "payable_accounts" || property == "receivable_accounts")
                            actions.push(`${property}.cancel`);
                        if(property == "business")
                            actions.push(`${property}.update_menu`);
                    }
                    
                }
                
                break;
            }
            if(!(user.role.permissions[property] instanceof Array) && property != 'root'){
                for(const action in user.role.permissions[property]){
                    // console.log(action, user.role.permissions[property][action])
                    if(user.role.permissions[property][action]){
                        actions.push(`${property}.${action}`);
                    }
                }
            }
        }
        //business
        
        const business = user.business.find((item) => item.slug == store.state.business)
        if(business != undefined){
            for (const property in business.menu) {
                if(business.menu[property]){
                    actions.push(`business_permission_${property}.show`)
                }
            }
        }
       
        
       
        Vue.prototype.$gates.setPermissions(actions);
        // console.log(Vue.prototype.$gates.getPermissions());
    }

    static clear()
    {
        
    }




    static checkRoutePermission(routeData)
    {
        // console.log(routeData);
        // if(Vue.prototype.$gates.getPermissions().length == 0)
        //     return true;
        if(Vue.prototype.$gates.hasAllPermissions(routeData.meta.permissions??'all'))
            return true;
            
        return false;
    }
   
}

