
import VueGatesPlugin from '../plugins/vue-gates';
import store from '../store';

export default function VueGateMiddleware({to,next}) {
    let watcher = setInterval(() => {
        if (store.state.userData != null) {
            // console.log("ejecutadio")
            if(checkPermission(to)){
                // console.log("fue next", checkPermission(to), to);
                next();
            }
            else{
                // console.log("fue /", checkPermission(to), to);
                next("Login");
                // return "/login"
                // console.log("se fue")
               
            }
            clearInterval(watcher);
        }
    }, 10);
   
}







function checkPermission(params) {
    return VueGatesPlugin.checkRoutePermission(params);
}