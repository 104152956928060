<template>
    <v-card height="500" class="overflow-scroll test-scroll" :loading="loading">
        
        <v-card-text>
            <!-- buttons -->
            <v-row>
                <!-- <v-col cols="4" class="pr-0 mx-0">
                    
                </v-col> -->
                <v-col class=" mx-0" >
                    <div class="card-top-buttons ">
                        <p class="card-title">
                            {{title}}
                        </p>
                        <!-- buttons calendar -->
                        <div class="card-top-buttons-cont">
                            <alternativeDatePickerRangeVCalendar
                                class="alt-date-pick-charbartype"
                                :valueInput="{start: dateRange[0], end: dateRange[1]}"
                                @valueChange="(v) => {dateRange[0] = v.start; dateRange[1] = v.end; resetPage(); makeRequest();}"
                                label="Periodo"
                            ></alternativeDatePickerRangeVCalendar>
                            <!-- <doubleAlternDateRangePicker
                            label="Periodo"
                            :valueInput="dateRange"
                            :rule="[]"
                            @valueChange="(v) => {dateRange = v}"
                            @okClic="(v) => {resetPage(); makeRequest();}"
                        ></doubleAlternDateRangePicker> -->
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="line"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="btn-alt"
                                outlined
                                height="42"
                                >
                                <span class="black--text">{{currencySelected}}</span>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                @click="() =>{resetPage(); currencyChanged(item); makeRequest();}"
                                v-for="(item, index) in currencies"
                                :key="index"
                                >
                                <v-list-item-title class="black--text">{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        </div>
                    </div>
                    
                    
                </v-col>
                 
            </v-row>
            <!-- buttons -->
            <v-row>
                <v-col class="text-end pt-0 mt-0 "  cols="12">
                   
                    <alternativeButton
                        :class="`btn `"
                        :props="backBtn"
                        
                    ></alternativeButton>
                    <alternativeButton
                        :class="`ml-2 btn `"
                        :props="nextBtn"
                        
                    ></alternativeButton>
                </v-col>
                
            </v-row>

            <!-- graph -->
            <v-row>
                <v-col class="pt-0 mt-0">
                    <Bar
                        :chart-options="chartOptions"
                        :chart-data="chartData"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                        :width="width"
                        :height="height"
                        ref="bar"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        
    </v-card>

</template>
<script>
import Utils from '../helpers/Utils'

import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip,  BarElement, CategoryScale, LinearScale)

export default {
    props: ['valueInput', 'title','color', 'labels', 'chartDataInput', 'lastPage', 'loadData', 'loading'],
    components: { Bar },
    model: {
        prop: 'chartData',
        event: 'valueChange'
    },
   
    computed: {
        // value: {
        //     get: function() {
        //         return this.valueInput
        //     },
        //     set: function(value) {
                
        //         this.$emit('valueChange', value)
        //     }
        // },
        chartData: {
            get: function() {
                return this.chartDataInput
            },
            set: function(value) {
                
                this.$emit('valueChange', value)
            }
        },
    },
    data(){
        return{
            dateRange: ['2019-09-10', '2019-09-20'],
            currencySelected: "mxn",
            page: 1,
            // chartData: {
            //     labels: this.labels,
            //     datasets: [
            //         {
                        
            //             backgroundColor: this.color,
            //             data: this.values
            //         }
            //     ]
            // },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            maxRotation: 90,
                            minRotation: 90,
                            font: {
                                size: 10,
                            },
                            // callback: function(value) {
                            //     return Utils.currencyFormat(value,"")
                            // }
                        }
                    },
                    y: {
                        ticks: {
                            callback: function(value) {
                                return Utils.currencyFormat(value,"")
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        
                    },
                    tooltip:{
                        callbacks:{
                            title: (context) =>{
                                return context[0].label.replace(",", "")
                            }
                        }
                    }
                    
                    

                }
            },
            chartId: 'bar-chart',
            datasetIdKey: 'label',
            width: 350,
            height: 380,
            cssClasses: '',
            styles: {
                
                
            },
            plugins: [],
            
            

            backBtn: {
                text: "",
                icon: "mdi-chevron-left",
				to: "",
				block: false,
                textClass:"",
                // height: 30,
                click: ()=>{this.prevPage(); this.makeRequest()}
            },
            nextBtn: {
                text: "",
                icon: "mdi-chevron-right",
				to: "",
				block: false,
                textClass:"",
                // height: 30,
                click: ()=>{this.nextPage(); this.makeRequest()}
            },


            currencies:[
                {title: "mxn", value: "mxn"},
                {title: "usd", value: "usd"},
            ]

        }
    },
    mounted(){
        // this.getActualDates( new Date());
        this.getPrevRange(new Date());
        if(this.loadData)
            this.makeRequest();
    },

    methods:{
        resetPage()
        {
            this.page = 1;
        },
        getActualDates(dateToWork){
            let actualMonth = dateToWork.getMonth();
            let actualYear = dateToWork.getFullYear();
            let actualDate = new Date(actualYear, actualMonth, 1)

            let lastDate = Utils.addMonths(actualDate,1)
           
            this.dateRange = [`${actualYear}-${this.getMonthText(actualMonth)}-01`, `${lastDate.getFullYear()}-${this.getMonthText(lastDate.getMonth())}-01`];
            // console.log(actualMonth, actualYear, actualDate, lastDate);
        },
        getMonthText(monthValue){
           return monthValue >= 9 ? `${monthValue+1}`:`0${monthValue+1}`
        },
        getNextRange()
        {
            let activeDate = this.dateRange[1].split("-");
            // let arrayDate = activeDate.
            let dateValue = new Date(activeDate[0], activeDate[1]-1,1);
            // let nextStart = Utils.addMonths(dateValue,1);
            this.getActualDates(dateValue);
        },
        getPrevRange(dateToWork)
        {
            // // // // let activeDate = this.dateRange[0].split("-");
            // // // // // let arrayDate = activeDate.
            // // // // let dateValue = new Date(activeDate[0], activeDate[1]-1,1);
            let actualMonth = dateToWork.getMonth();
            let actualYear = dateToWork.getFullYear();
            let actualDate = new Date(actualYear, actualMonth, 1)
            let prev = Utils.subMonths(actualDate,1);
            // this.getActualDates(dateValue);
            // console.log(prev);
            this.getActualDates(prev);
        },
        nextPage()
        {
            console.log(this.page, this.lastPage)
            if(this.page >= this.lastPage)
                return;
            this.page = this.page + 1;
        },
        prevPage()
        {
            
            if(this.page == 1)
                return;
            this.page = this.page - 1;
        },
        checkClickAppend()
        {
            this.$emit('clickAppend');
        },
        currencyChanged(item)
        {
            
            this.currencySelected = item.value;
        },

        makeRequest()
        {
            // console.log(this.dateRange, this.currencySelected);
            this.$emit('makeRequest', {dates: this.dateRange, currency: this.currencySelected, page:this.page});
        }

    }


   
}
</script>

<style lang="scss">
    .card{
        &-title{
            color: #241764;
            font-size: 11px;
            padding-bottom: 0;
            margin-bottom: 0 !important;
            padding-top: 10px;
            padding-left: 5px;
            font-weight: bold;
        }

        &-top-buttons{
            display: flex;
            gap: 5px;
            // justify-content: end;
            justify-content: space-between !important;

            &-cont{
                display: flex;
                gap: 5px;
            }
        }
    }

    .btn-alt{
        min-width: 20px !important;
        padding: 7px !important;
        font-size: 10px !important;
    }

    .overflow-scroll{
        overflow: auto;
    }

    .alt-date-pick-charbartype{
        min-width: 15rem;
        label{
            font-size: 12px !important;
        }
        input{
            font-size: 13px !important;
        }
    }
</style>