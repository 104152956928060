<template>
    <v-btn  :max-height="props.height"  outlined color="transparent" class="border alternative-button" elevation="0" :block="props.block" :to="{ name: props.to }" @click="props.click" >
        <div :class="`${props.textClass != undefined && props.textClass != '' ? props.textClass:'grey--text text--darken-3'}`" >
            <v-icon>{{props.icon}}</v-icon>
        </div>
       
    </v-btn>
</template>
<script>
    export default {
        name: 'alternativeButton',
        props: ['props']
    }
</script>

<style lang="scss">
    .alternative-button{
        min-width: 1rem !important;
        padding: 0.3125rem !important;
        
    }

   

    .border{
        border: $lineColor-1 1px solid;
    }
</style>