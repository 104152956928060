const permissions = {
  root: false,
  roles: {},
  users: {},
  receivable_accounts: {},
  payable_accounts: {},
  incomes: {},
  outcomes: {},
  customers: {},
  providers: {},
  banks: {},
  accounts: {},
  sub_accounts: {},
  sub_sub_accounts: {},
  auxiliaries: {},
  concepts: {},
  references: {},
  payment_methods: {},
  bank_accounts: {},
  target_accounts: {},
  business: {},
  invoices: {},
  depositos: {},
  retiros: {},
  transfers: {},
  balance_due_ra: {},
  balance_due_pa: {},
  balance_due_customers: {},
  balance_due_providers: {},
  incomes_timelapse: {},
  payments_concepts: {},
  payments_bank_accounts: {},
  customs_agents:{},
  'ca_payable_accounts':{},
  balance_paid_providers:{},
  balance_paid_customers:{},
  pece_accounts:{},
  advances:{},
  payments:{},
  demoras:{},
  warranties:{},
  credit_notes:{},
  balance_due_ca_pa:{},
  contractor_payable_accounts:{},
  contractor_payments:{},
  contractor_warranties:{},
  contractor_aditivas:{},
  contractor_deductivas:{},
  constructions_receivable_accounts:{},
  constructions_estimations:{},
  constructions_warranties:{},
  constructions_aditivas:{},
  constructions_deductivas:{},
  extraordinaries:{},
  'construction-types': {},
  dashboard:{},
  contractors_balance:{},
  income_credit_notes:{},
  ca_positive_balances:{},
  cxc_timelapse:{},
  cxc_customers: {},
  outcomes_timelapse:{},
  reports_contractors_cxc:{},
  rrhh_payroll_payment: {},
  rrhh_paysheet: {},
  rrhh_reports_annual_budget: {},
  reports_customers_payment_history:{},
  catalogs:{},
  loans: {},
  constructions:{},
  billing_receivable_accounts: {},
  loans_pending: {},
  other_incomes_timerange:{},
  income_loans: {},
  outcomes_loans: {},
  income_statement: {},
};

export default permissions;
